import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { AssetDescriptionService } from '../../../../services/configs/asset-description.service';
import { ConstantsService } from '../../../../services/constants/constants.service';
import { ClientsService } from '../../../../services/account/clients.service';
import { AssetGroupTypeService} from '../../../../services/configs/asset-group-type.service';
import { AssetClassService} from '../../../../services/configs/asset-class.service';
import { AccountingGroupService} from '../../../../services/configs/accounting-group.service';
import { AccSubGroupService} from '../../../../services/configs/acc-sub-group.service';

@Component({
  selector: 'app-asset-description',
  templateUrl: './asset-description.component.html',
  styleUrls: ['./asset-description.component.scss']
})
export class AssetDescriptionComponent implements OnInit {
  formValue: any;
  newAssetDescEvent: boolean;
  notifier: any;
  clients: any[] = [];
  assets: any[] = [];
  groupTypes: any[] = [];
  SubGroups: any;
  groups: any;
  classAssets: any;

  constructor(
    private  assetDescriptionService: AssetDescriptionService,
    private router: Router,
    private formBuilder: FormBuilder,
    private clientsService: ClientsService,
    private accountingGroupService: AccountingGroupService,
    private accSubGroupService: AccSubGroupService,
    private assetGroupTypeService: AssetGroupTypeService,
    private assetClassService: AssetClassService,
    private spinner: NgxSpinnerService,
    private constantsService: ConstantsService,
    private notifierService: NotifierService
  ) {
    this.notifier = notifierService;
   }

  ngOnInit(): void {
    this.initForm();
    this.getAssets();
    this.getClients();
    
  }

  initForm() {
    this.newAssetDescEvent = true;
    this.formValue = this.formBuilder.group({
      assetTypeName: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      company: new FormControl('', [Validators.required]),
      accountingGroup: new FormControl('', [Validators.required]),
      accountingSubGroup: new FormControl('', [Validators.required]),
      assetClass: new FormControl('', [Validators.required]),
      assetGroupType: new FormControl('', [Validators.required]),
      assetDescGuid: new FormControl(''),
      
    
    });
  }
  getAssets() {
    this.spinner.show(); // showing loader spinner
    this.assetDescriptionService.getAllAssets().subscribe((results) => {
      console.log('Assets', results);
      this.assets = results;
      this.spinner.hide(); // closing loading spinner
    }, error => {
      this.spinner.hide(); // closing loading spinner
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator');
      }
    });
  }

  getClients() {
    this.spinner.show(); // showing loader spinner
    this.clientsService.getAllClients().subscribe((results) => {
      console.log('Clients', results);
      this.clients = results;
      this.spinner.hide(); // closing loading spinner
    }, error => {
      this.spinner.hide(); // closing loading spinner
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator');
      }
    });
  }

  getGroupType(value: any) {
    this.spinner.show(); // showing loader spinner
    this.assetGroupTypeService.searchGroupType('maniClientGuid', value).subscribe((results) => {
      console.log('GroupTypes', results);
      this.groupTypes = results;
      this.spinner.hide(); // closing loading spinner
    }, error => {
      this.spinner.hide(); // closing loading spinner
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator');
      }
    });
  }

  getClassAssets(value: any) {
    this.spinner.show(); // showing loader spinner
    this.assetClassService.searchClassAsset('maniClientGuid', value).subscribe((results) => {
      console.log('ClassAssets', results);
      this.classAssets = results;
      this.spinner.hide(); // closing loading spinner
    }, error => {
      this.spinner.hide(); // closing loading spinner
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator');
      }
    });
  }

  getGroup(value: any) {
    this.spinner.show(); // showing loader spinner
    this.accountingGroupService.searchAccountingGroup('maniClientGuid', value).subscribe((results) => {
      console.log('Groups', results);
      this.groups = results;
      this.spinner.hide(); // closing loading spinner
    }, error => {
      this.spinner.hide(); // closing loading spinner
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator');
      }
    });
  }


  getSubGroup(value: any) {
    this.spinner.show(); // showing loader spinner
    this.accSubGroupService.searchAccSubGroup('maniClientGuid', value).subscribe((results) => {
      console.log('SubGroups', results);
      this.SubGroups = results;
      this.spinner.hide(); // closing loading spinner
    }, error => {
      this.spinner.hide(); // closing loading spinner
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator');
      }
    });
  }


  onCompanyChange(value: any){
    console.log(value);
    this.getGroupType(value);
    this.getClassAssets(value);
    this.getGroup(value);
    this.getSubGroup(value);
  }

  addNewAsset() {
    this.spinner.show(); // showing loader spinner
    console.log(this.formValue.value);

    if (this.formValue.valid) {

      // JSON Data to be sent to the server
      const data = {
        'assetTypeName': this.formValue.value.assetTypeName,
        'description': this.formValue.value.description,
        'maniClientGuid': this.formValue.value.company,
        'assetGroupTypeGuid': this.formValue.value.assetGroupType,
        'assetDescGuid': this.formValue.value.assetDescGuid,
        'accSubGroupGuid': this.formValue.value.accountingSubGroup,
        'accountingGroupGuid': this.formValue.value.accountingGroupGuid,
        'assetClassGuid': this.formValue.value.assetClass      
        
      };

      // auto click close button
      const cancelRef = document.getElementById('cancel');

      this.assetDescriptionService.addAsset(data).subscribe(results => {
        console.log(results);
        this.spinner.hide();
        this.getAssets(); // reload data on the screen
        cancelRef?.click();
        console.log(results);
        this.notifier.notify('success', results?.responseMessage);
      }, error => {
        this.spinner.hide();
        console.log(error);
        if (error.status === 401) {
          this.notifier.notify('error', 'You need to login again');
          this.router.navigateByUrl('login');
        } else if (error.status === 409) {
          this.notifier.notify('warning', 'Already exists');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator'
          );
        }
        cancelRef?.click();
        // this.spinner.hide();
      });

      this.initForm(); // clear form
    } else {
      this.spinner.hide();
      this.notifier.notify('error', 'Fill in all the form fields');
    }
  
  }


  updateAsset() {
    this.spinner.show(); // showing loader spinner
    console.log(this.formValue.value);

    if (this.formValue.valid) {

      if (!confirm(`Are you sure you want to save these changes?`)) {
        this.spinner.hide();
        return;
      }

      // JSON Data to be sent to the server
      const data = {
       'assetTypeName': this.formValue.value.assetTypeName,
        'description': this.formValue.value.description,
        'maniClientGuid': this.formValue.value.company,
        'assetGroupTypeGuid': this.formValue.value.assetGroupType,
        'assetDescGuid': this.formValue.value.assetDescGuid,
        'accSubGroupGuid': this.formValue.value.accountingSubGroup,
        'accountingGroupGuid': this.formValue.value.accountingGroupGuid,
        'assetClassGuid': this.formValue.value.assetClass      
      };

      console.log(data);

      // auto click close button
      const cancelRef = document.getElementById('cancel');

      this.assetDescriptionService.updateAsset(data).subscribe(results => {
        // console.log(results);
        this.spinner.hide();
        this.getAssets(); // reload data on the screen
        cancelRef?.click();
        console.log(results);
        this.notifier.notify('success', results?.responseMessage);
      }, error => {
        this.spinner.hide();
        console.log(error);
        if (error.status === 401) {
          this.notifier.notify('error', 'You need to login again');
          this.router.navigateByUrl('login');
        } else if (error.status === 409) {
          this.notifier.notify('warning', 'Already exists');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator'
          );
        }
        cancelRef?.click();
        // this.spinner.hide();
      });

      this.initForm(); // clear form
    } else {
      this.spinner.hide();
      console.log('Form not valid!!!!');
    }
  }
  
  saveChanges() {
    if (this.newAssetDescEvent) {
      this.addNewAsset(); // add new 
    } else {
      this.updateAsset(); // update existing 
    }
  }


  
  openAsset(asset: any) {
    console.log(asset);
    this.newAssetDescEvent = false;
    this.formValue.reset();
    const btnAsset = document.getElementById('btnAsset');
    btnAsset.click();

    this.formValue.controls['assetTypeName'].setValue(asset.assetTypeName);
    this.formValue.controls['description'].setValue(asset.description);
    this.formValue.controls['company'].setValue(asset.maniClientGuid);
    this.formValue.controls['assetGroupType'].setValue(asset.assetGroupTypeGuid);
    this.formValue.controls['accountingGroup'].setValue(asset.accountingGroupGuid);
    this.formValue.controls['accountingSubGroup'].setValue(asset.accSubGroupGuid);
    this.formValue.controls['assetClass'].setValue(asset.assetClassGuid);
    
  }


  deleteAsset(asset: any) {
    this.spinner.show(); // showing loader spinner

    if (!confirm(`Are you sure you want to delete this group (${asset.assetTypeName})?`)) {
      this.spinner.hide();
      return;
    }

    // auto click close button
    const cancelRef = document.getElementById('cancel');

    this.assetDescriptionService.deleteAsset(asset.assetDescGuid).subscribe(results => {
      // console.log(results);
      this.spinner.hide();
      this.getAssets(); // reload data on the screen
      cancelRef?.click();
      console.log(results);
      this.notifier.notify('success', results?.responseMessage);
    }, error => {
      this.spinner.hide();
      console.log(error);
      if (error.status === 401) {
        this.notifier.notify('error', 'You need to login again');
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator'
        );
      }
      cancelRef?.click();
      // this.spinner.hide();
    });
  }

}
