import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { AssetGroupTypeService } from '../../../../services/configs/asset-group-type.service';
import { ConstantsService } from '../../../../services/constants/constants.service';
import { ClientsService } from '../../../../services/account/clients.service';
import { AssetClassService} from '../../../../services/configs/asset-class.service';
import { AccountingGroupService} from '../../../../services/configs/accounting-group.service';
import { AccSubGroupService} from '../../../../services/configs/acc-sub-group.service';


@Component({
  selector: 'app-asset-group-type',
  templateUrl: './asset-group-type.component.html',
  styleUrls: ['./asset-group-type.component.scss']
})
export class AssetGroupTypeComponent implements OnInit {
  newGroupTypeEvent: boolean;
  formValue: FormGroup;
  clients: any[] = [];
  groupTypes: any[] = [];
  notifier: any;
  classAssets: any;
  SubGroups: any;
  groups: any;

  constructor(
    private assetGroupTypeService:  AssetGroupTypeService,
    private router: Router,
    private formBuilder: FormBuilder,
    private clientsService: ClientsService,
    private accountingGroupService: AccountingGroupService,
    private accSubGroupService: AccSubGroupService,
    private assetClassService: AssetClassService,
    private spinner: NgxSpinnerService,
    private constantsService: ConstantsService,
    private notifierService: NotifierService
  ) {
    this.notifier = notifierService;
   }

  ngOnInit(): void {
    this.initForm();
    this.getGroupTypes();
    this.getClients();
    
  }
  
  initForm() {
    this.newGroupTypeEvent = true;
    this.formValue = this.formBuilder.group({
      assetGroupTypeName: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      company: new FormControl('', [Validators.required]),
      accountingSubGroup: new FormControl('', [Validators.required]),
      accountingGroup: new FormControl('', [Validators.required]),
      assetClass: new FormControl('', [Validators.required]),
      assetGroupTypeGuid: new FormControl('')
    });
  }

  getGroupTypes() {
    this.spinner.show(); // showing loader spinner
    this.assetGroupTypeService.getAllGroupType().subscribe((results) => {
      console.log('GroupTypes', results);
      this.groupTypes = results;
      this.spinner.hide(); // closing loading spinner
    }, error => {
      this.spinner.hide(); // closing loading spinner
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator');
      }
    });
  }

  getClients() {
    this.spinner.show(); // showing loader spinner
    this.clientsService.getAllClients().subscribe((results) => {
      console.log('Clients', results);
      this.clients = results;
      this.spinner.hide(); // closing loading spinner
    }, error => {
      this.spinner.hide(); // closing loading spinner
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator');
      }
    });
  }

  getClassAssets(value: any) {
    this.spinner.show(); // showing loader spinner
    this.assetClassService.searchClassAsset('maniClientGuid', value).subscribe((results) => {
      console.log('ClassAssets', results);
      this.classAssets = results;
      this.spinner.hide(); // closing loading spinner
    }, error => {
      this.spinner.hide(); // closing loading spinner
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator');
      }
    });
  }

  getGroup(value: any) {
    this.spinner.show(); // showing loader spinner
    this.accountingGroupService.searchAccountingGroup('maniClientGuid', value).subscribe((results) => {
      console.log('Groups', results);
      this.groups = results;
      this.spinner.hide(); // closing loading spinner
    }, error => {
      this.spinner.hide(); // closing loading spinner
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator');
      }
    });
  }


  getSubGroup(value: any) {
    this.spinner.show(); // showing loader spinner
    this.accSubGroupService.searchAccSubGroup('maniClientGuid', value).subscribe((results) => {
      console.log('SubGroups', results);
      this.SubGroups = results;
      this.spinner.hide(); // closing loading spinner
    }, error => {
      this.spinner.hide(); // closing loading spinner
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator');
      }
    });
  }

  onCompanyChange(value: any){
    console.log(value);
    this.getClassAssets(value);
    this.getGroup(value);
    this.getSubGroup(value);
  }

  addNewGroupType() {
    this.spinner.show(); // showing loader spinner
    console.log(this.formValue.value);

    if (this.formValue.valid) {

      // JSON Data to be sent to the server
      const data = {
        'assetGroupTypeName': this.formValue.value.assetGroupTypeName,
        'description': this.formValue.value.description,
        'maniClientGuid': this.formValue.value.company,
        'accSubGroupGuid': this.formValue.value.accountingSubGroup,
        'accountingGroupGuid': this.formValue.value.accountingGroupGuid,
        'assetClassGuid': this.formValue.value.assetClass       
      };

      console.log(data);

      // auto click close button
      const cancelRef = document.getElementById('cancel');

      this.assetGroupTypeService.addGroupType(data).subscribe(results => {
        console.log(results);
        this.spinner.hide();
        this.getGroupTypes(); // reload data on the screen
        cancelRef?.click();
        console.log(results);
        this.notifier.notify('success', results?.responseMessage);
      }, error => {
        this.spinner.hide();
        console.log(error);
        if (error.status === 401) {
          this.notifier.notify('error', 'You need to login again');
          this.router.navigateByUrl('login');
        } else if (error.status === 409) {
          this.notifier.notify('warning', 'Already exists');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator'
          );
        }
        cancelRef?.click();
        // this.spinner.hide();
      });

      this.initForm(); // clear form
    } else {
      this.spinner.hide();
      this.notifier.notify('error', 'Fill in all the form fields');
    }
  }

  updateGroupType() {
    this.spinner.show(); // showing loader spinner
    console.log(this.formValue.value);

    if (this.formValue.valid) {

      if (!confirm(`Are you sure you want to save these changes?`)) {
        this.spinner.hide();
        return;
      }

      // JSON Data to be sent to the server
      const data = {
       'assetGroupTypeName': this.formValue.value.assetGroupTypeName,
       'description': this.formValue.value.description,
       'maniClientGuid': this.formValue.value.company,
       'accSubGroupGuid': this.formValue.value.accountingSubGroup,
       'accountingGroupGuid': this.formValue.value.accountingGroup,
       'assetClassGuid': this.formValue.value.assetClass,
       'assetGroupTypeGuid': this.formValue.value.assetGroupTypeGuid
      };

      console.log(data);

      // auto click close button
      const cancelRef = document.getElementById('cancel');

      this.assetGroupTypeService.updateGroupType(data).subscribe(results => {
        // console.log(results);
        this.spinner.hide();
        this.getGroupTypes(); // reload data on the screen
        cancelRef?.click();
        console.log(results);
        this.notifier.notify('success', results?.responseMessage);
      }, error => {
        this.spinner.hide();
        console.log(error);
        if (error.status === 401) {
          this.notifier.notify('error', 'You need to login again');
          this.router.navigateByUrl('login');
        } else if (error.status === 409) {
          this.notifier.notify('warning', 'Already exists');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator'
          );
        }
        cancelRef?.click();
        // this.spinner.hide();
      });

      this.initForm(); // clear form
    } else {
      this.spinner.hide();
      console.log('Form not valid!!!!');
    }
  }
  
  saveChanges() {
    if (this.newGroupTypeEvent) {
      this.addNewGroupType(); // add new 
    } else {
      this.updateGroupType(); // update existing 
    }
  }


  
  openGroupType(groupType: any) {
    console.log(groupType);
    this.newGroupTypeEvent = false;
    this.formValue.reset();
    const btnGroupType = document.getElementById('btnGroupType');
    btnGroupType.click();

    this.formValue.controls['assetGroupTypeName'].setValue(groupType.assetGroupTypeName);
    this.formValue.controls['description'].setValue(groupType.description);
    this.formValue.controls['company'].setValue(groupType.maniClientGuid);
    this.formValue.controls['accountingGroup'].setValue(groupType.accountingGroupGuid);
    this.formValue.controls['accountingSubGroup'].setValue(groupType.accSubGroupGuid);
    this.formValue.controls['assetClass'].setValue(groupType.assetClassGuid);
    this.formValue.controls['assetGroupTypeGuid'].setValue(groupType.assetGroupTypeGuid);
  }


  deleteGroupType(groupType: any) {
    this.spinner.show(); // showing loader spinner

    if (!confirm(`Are you sure you want to delete this group (${groupType.assetGroupTypeName})?`)) {
      this.spinner.hide();
      return;
    }

    // auto click close button
    const cancelRef = document.getElementById('cancel');

    this.assetGroupTypeService.deleteGroupType(groupType.assetGroupTypeGuid).subscribe(results => {
      // console.log(results);
      this.spinner.hide();
      this.getGroupTypes(); // reload data on the screen
      cancelRef?.click();
      console.log(results);
      this.notifier.notify('success', results?.responseMessage);
    }, error => {
      this.spinner.hide();
      console.log(error);
      if (error.status === 401) {
        this.notifier.notify('error', 'You need to login again');
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator'
        );
      }
      cancelRef?.click();
      // this.spinner.hide();
    });
  }


}
