import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { RolesService } from '../../../services/account/roles.service';
import { UserService } from '../../../services/account/user.service';
import { ConstantsService } from '../../../services/constants/constants.service';
import { ClientsService } from '../../../services/account/clients.service';

@Component({
  selector: 'app-mani-users',
  templateUrl: './mani-users.component.html',
  styleUrls: ['./mani-users.component.scss']
})
export class ManiUsersComponent implements OnInit {

  data: any[] = [];
  roles: any[] = [];
  clients: any[] = [];
  formValue !: FormGroup;
  userPasswordFormValue !: FormGroup;
  private readonly notifier: NotifierService;

  newUserEvent: boolean;

  page: number = 1;
  totalRecords!: any;



  constructor(
    private userService: UserService,
    private roleService: RolesService,
    private clientsService: ClientsService,
    private router: Router,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private constantsService: ConstantsService,
    private notifierService: NotifierService
  ) {
    this.notifier = notifierService;
  }

  ngOnInit(): void {
    this.initForm();
    this.getRoles();
    this.getUsers();
    this.getClients();
  }

  initForm() {
    this.newUserEvent = true;
    this.formValue = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      mobile: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      userRole: new FormControl('', [Validators.required]),
      company: new FormControl('', [Validators.required]),
      userGuid: new FormControl(''),
    });

    this.userPasswordFormValue = this.formBuilder.group({
      userPassword: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      userGuid: new FormControl('', [Validators.required])
    });
  }

  // Get all users from User service
  getUsers() {
    this.spinner.show(); // showing loader spinner
    this.userService.getAllUsers().subscribe((results) => {
      console.log('Users', results);
      this.data = results;
      this.spinner.hide(); // closing loading spinner
    }, error => {
      this.spinner.hide(); // closing loading spinner
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator');
      }
    });
  }

  // Get all roles from the Role service
  getRoles() {
    this.spinner.show(); // showing loader spinner
    this.roleService.getAllRoles().subscribe((results) => {
      console.log('Roles', results);
      this.roles = results;
      this.spinner.hide(); // closing loading spinner
    }, error => {
      this.spinner.hide(); // closing loading spinner
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator');
      }
    });
  }

  // Get all clients from the client service
  getClients() {
    this.spinner.show(); // showing loader spinner
    this.clientsService.getAllClients().subscribe((results) => {
      console.log('Clients', results);
      this.clients = results;
      this.spinner.hide(); // closing loading spinner
    }, error => {
      this.spinner.hide(); // closing loading spinner
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator');
      }
    });
  }

  // Add new user
  addNewUser() {
    this.spinner.show(); // showing loader spinner
    console.log(this.formValue.value);

    if (this.formValue.valid) {

      // JSON Data to be sent to the server
      const data = {
        'address': '',
        'email': this.formValue.value.email,
        'firstName': this.formValue.value.firstName,
        'gender': '',
        'lastName': this.formValue.value.lastName,
        'mobile': this.formValue.value.mobile,
        'password': this.formValue.value.password,
        'companyGuid': this.formValue.value.company,
        'userType': this.formValue.value.userRole
      };

      // auto click close button
      const cancelRef = document.getElementById('cancel');

      this.userService.addUser(data).subscribe(results => {
        console.log(results);
        this.spinner.hide();
        this.getUsers(); // reload data on the screen
        cancelRef?.click();
        console.log(results);
        this.notifier.notify('success', results?.responseMessage);
      }, error => {
        this.spinner.hide();
        console.log(error);
        if (error.status === 401) {
          this.notifier.notify('error', 'You need to login again');
          this.router.navigateByUrl('login');
        } else if (error.status === 409) {
          this.notifier.notify('warning', 'Already exists');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator'
          );
        }
        cancelRef?.click();
        // this.spinner.hide();
      });

      this.initForm(); // clear form
    } else {
      this.spinner.hide();
      this.notifier.notify('error', 'Fill in all the form fields');
    }
  }

  updateUser() {
    this.spinner.show(); // showing loader spinner
    console.log(this.formValue.value);

    if (this.formValue.valid) {

      if (!confirm(`Are you sure you want to save these changes?`)) {
        this.spinner.hide();
        return;
      }

      // JSON Data to be sent to the server
      const data = {
        'address': '',
        'email': this.formValue.value.email,
        'firstName': this.formValue.value.firstName,
        'gender': '',
        'lastName': this.formValue.value.lastName,
        'mobile': this.formValue.value.mobile,
        'password': this.formValue.value.password,
        'companyGuid': this.formValue.value.company,
        'userType': this.formValue.value.userRole,
        'userGuid': this.formValue.value.userGuid
      };

      // auto click close button
      const cancelRef = document.getElementById('cancel');

      this.userService.updateUser(data).subscribe(results => {
        // console.log(results);
        this.spinner.hide();
        this.getUsers(); // reload data on the screen
        cancelRef?.click();
        console.log(results);
        this.notifier.notify('success', results?.responseMessage);
      }, error => {
        this.spinner.hide();
        console.log(error);
        if (error.status === 401) {
          this.notifier.notify('error', 'You need to login again');
          this.router.navigateByUrl('login');
        } else if (error.status === 409) {
          this.notifier.notify('warning', 'Already exists');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator'
          );
        }
        cancelRef?.click();
        // this.spinner.hide();
      });

      this.initForm(); // clear form
    } else {
      this.spinner.hide();
      console.log('Form not valid!!!!');
    }
  }

  saveChanges() {
    if (this.newUserEvent) {
      this.addNewUser();
    } else {
      this.updateUser();
    }
  }
  // Browse/Open user information
  openUser(user: any) {
    const btnUser = document.getElementById('btnUser');
    btnUser.click();
    console.log(user);
    this.newUserEvent = false;
    this.formValue.reset();
    this.formValue.controls['firstName'].setValue(user.user.firstName);
    this.formValue.controls['lastName'].setValue(user.user.lastName);
    this.formValue.controls['mobile'].setValue(user.user.mobile);
    this.formValue.controls['email'].setValue(user.user.email);
    this.formValue.controls['company'].setValue(user.user.companyGuid);
    this.formValue.controls['userRole'].setValue(user.user.userType);
    this.formValue.controls['userGuid'].setValue(user.user.userGuid);
  }

  openUserPasswordModal(user) {
    const btnUser = document.getElementById('btnUserPassword');
    btnUser.click();
    console.log(user);
    this.newUserEvent = false;
    this.userPasswordFormValue.reset();
    this.userPasswordFormValue.controls['userGuid'].setValue(user.user.userGuid);
    this.userPasswordFormValue.controls['firstName'].setValue(user.user.firstName);
  }

  deleteUser(user: any) {
    this.spinner.show(); // showing loader spinner

    if (!confirm(`Are you sure you want to delete this user (${user.user.firstName})?`)) {
      this.spinner.hide();
      return;
    }

    // auto click close button
    const cancelRef = document.getElementById('cancel');

    this.userService.deleteUser(user.user.userGuid).subscribe(results => {
      // console.log(results);
      this.spinner.hide();
      this.getUsers(); // reload data on the screen
      cancelRef?.click();
      console.log(results);
      this.notifier.notify('success', results?.responseMessage);
    }, error => {
      this.spinner.hide();
      console.log(error);
      if (error.status === 401) {
        this.notifier.notify('error', 'You need to login again');
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator'
        );
      }
      cancelRef?.click();
      // this.spinner.hide();
    });
  }

  // change user password
  changeUserPasswordFunction() {
    this.spinner.show(); // showing loader spinner
    console.log(this.userPasswordFormValue.value);

    if (!confirm(`Are you sure you want to change password for this user (${this.userPasswordFormValue.value.firstName})?`)) {
      this.spinner.hide();
      return;
    }


    if (this.userPasswordFormValue.valid) {

      // JSON Data to be sent to the server
      const data = {
        'password': this.userPasswordFormValue.value.userPassword,
        'userGuid': this.userPasswordFormValue.value.userGuid
      };

      // auto click close button
      const cancelRef = document.getElementById('closeModal');

      this.userService.changeUserPassword(data).subscribe(results => {
        console.log(results);
        this.spinner.hide();
        this.getUsers(); // reload data on the screen
        cancelRef?.click();
        console.log(results);
        this.notifier.notify('success', results?.responseMessage);
      }, error => {
        this.spinner.hide();
        console.log(error);
        if (error.status === 401) {
          this.notifier.notify('error', 'You need to login again');
          this.router.navigateByUrl('login');
        } else if (error.status === 409) {
          this.notifier.notify('warning', 'Already exists');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator'
          );
        }
        cancelRef?.click();
        // this.spinner.hide();
      });

      this.initForm(); // clear form
    } else {
      this.spinner.hide();
      this.notifier.notify('error', 'Fill in all the form fields');
    }
  }

}
