import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { baseUrl } from '../../../environments/environment';
import { ConstantsService } from '../constants/constants.service';
import { TokenService } from '../constants/token.service';

@Injectable({
  providedIn: 'root'
})
export class CogtaAssetsService {

  constructor(private http: HttpClient, private tokenStorageService: TokenService, private constants: ConstantsService) { }


  getAllAssets(): Observable<any> {
    return this.http.get(`${baseUrl}/api/assetopti/cogta/asset/v1/list-all`, { headers: this.constants.getHeaders() });
  }
  
  //Add 
  addCogtaAsset(data: any): Observable<any> {
   return this.http.post(`${baseUrl}/api/assetopti/cogta/asset/v1/create`, data, { headers: this.constants.getHeaders() });
  }


}
