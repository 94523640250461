import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountingGroupService } from '../../../../services/configs/accounting-group.service';
import { ConstantsService } from '../../../../services/constants/constants.service';
import { ClientsService } from '../../../../services/account/clients.service';


@Component({
  selector: 'app-accounting-group',
  templateUrl: './accounting-group.component.html',
  styleUrls: ['./accounting-group.component.scss']
})
export class AccountingGroupComponent implements OnInit {

  formValue: FormGroup;
  newAccountingGroupEvent: boolean;
  
  clients: any[] = [];
  groups: any[] = [];
  notifier: any;
  

  constructor(
    private  accountingGroupService:  AccountingGroupService,
    private router: Router,
    private formBuilder: FormBuilder,
    private clientsService: ClientsService,
    private spinner: NgxSpinnerService,
    private constantsService: ConstantsService,
    private notifierService: NotifierService
  ) {
    this.notifier = notifierService;
   }

  ngOnInit(): void {
    this.initForm();
    this.getGroup();
    this.getClients();
  }
  
  

  initForm() {
    this.newAccountingGroupEvent = true;
    this.formValue = this.formBuilder.group({
      accountingGroupName: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      company: new FormControl('', [Validators.required]),
      accountingGroupGuid: new FormControl('')
    });
  }

  getGroup() {
    this.spinner.show(); // showing loader spinner
    this.accountingGroupService.getAllGroup().subscribe((results) => {
      console.log('Groups', results);
      this.groups = results;
      this.spinner.hide(); // closing loading spinner
    }, error => {
      this.spinner.hide(); // closing loading spinner
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator');
      }
    });
  }

  getClients() {
    this.spinner.show(); // showing loader spinner
    this.clientsService.getAllClients().subscribe((results) => {
      console.log('Clients', results);
      this.clients = results;
      this.spinner.hide(); // closing loading spinner
    }, error => {
      this.spinner.hide(); // closing loading spinner
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator');
      }
    });
  }
  

  addNewGroup() {
    this.spinner.show(); // showing loader spinner
    console.log(this.formValue.value);

    if (this.formValue.valid) {

      // JSON Data to be sent to the server
      const data = {
        'accountingGroupName': this.formValue.value.accountingGroupName,
        'description': this.formValue.value.description,
        'maniClientGuid': this.formValue.value.company      
      };

      console.log(data);

      // auto click close button
      const cancelRef = document.getElementById('cancel');

      this.accountingGroupService.addGroup(data).subscribe(results => {
        console.log(results);
        this.spinner.hide();
        this.getGroup(); // reload data on the screen
        cancelRef?.click();
        console.log(results);
        this.notifier.notify('success', results?.responseMessage);
      }, error => {
        this.spinner.hide();
        console.log(error);
        if (error.status === 401) {
          this.notifier.notify('error', 'You need to login again');
          this.router.navigateByUrl('login');
        } else if (error.status === 409) {
          this.notifier.notify('warning', 'Already exists');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator'
          );
        }
        cancelRef?.click();
        // this.spinner.hide();
      });

      this.initForm(); // clear form
    } else {
      this.spinner.hide();
      this.notifier.notify('error', 'Fill in all the form fields');
    }
  }
  

  updateGroup() {
    this.spinner.show(); // showing loader spinner
    console.log(this.formValue.value);

    if (this.formValue.valid) {

      if (!confirm(`Are you sure you want to save these changes?`)) {
        this.spinner.hide();
        return;
      }

      // JSON Data to be sent to the server
      const data = {
       'accountingGroupName': this.formValue.value.accountingGroupName,
        'description': this.formValue.value.description,
        'maniClientGuid': this.formValue.value.company,
        'accountingGroupGuid': this.formValue.value.accountingGroupGuid
      };

      console.log(data);

      // auto click close button
      const cancelRef = document.getElementById('cancel');

      this.accountingGroupService.updateGroup(data).subscribe(results => {
        // console.log(results);
        this.spinner.hide();
        this.getGroup(); // reload data on the screen
        cancelRef?.click();
        console.log(results);
        this.notifier.notify('success', results?.responseMessage);
      }, error => {
        this.spinner.hide();
        console.log(error);
        if (error.status === 401) {
          this.notifier.notify('error', 'You need to login again');
          this.router.navigateByUrl('login');
        } else if (error.status === 409) {
          this.notifier.notify('warning', 'Already exists');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator'
          );
        }
        cancelRef?.click();
        // this.spinner.hide();
      });

      this.initForm(); // clear form
    } else {
      this.spinner.hide();
      console.log('Form not valid!!!!');
    }
  }
  
  saveChanges() {
    if (this.newAccountingGroupEvent) {
      this.addNewGroup(); // add new role
    } else {
      this.updateGroup(); // update existing role
    }
  }


  
  openGroup(group: any) {
    console.log(group);
    this.newAccountingGroupEvent = false;
    this.formValue.reset();
    const btnGroup = document.getElementById('btnGroup');
    btnGroup.click();

    this.formValue.controls['accountingGroupName'].setValue(group.accountingGroupName);
    this.formValue.controls['description'].setValue(group.description);
    this.formValue.controls['company'].setValue(group.maniClientGuid);
    this.formValue.controls['accountingGroupGuid'].setValue(group.accountingGroupGuid);
  }


  deleteGroup(group: any) {
    this.spinner.show(); // showing loader spinner

    if (!confirm(`Are you sure you want to delete this group (${group.accountGroupName})?`)) {
      this.spinner.hide();
      return;
    }

    // auto click close button
    const cancelRef = document.getElementById('cancel');

    this.accountingGroupService.deleteGroup(group.accountingGroupGuid).subscribe(results => {
      // console.log(results);
      this.spinner.hide();
      this.getGroup(); // reload data on the screen
      cancelRef?.click();
      console.log(results);
      this.notifier.notify('success', results?.responseMessage);
    }, error => {
      this.spinner.hide();
      console.log(error);
      if (error.status === 401) {
        this.notifier.notify('error', 'You need to login again');
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator'
        );
      }
      cancelRef?.click();
      // this.spinner.hide();
    });
  }

}
