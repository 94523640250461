import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IdcAssetService } from '../../../../services/assets/idc-asset.service';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConstantsService } from '../../../../services/constants/constants.service';

import { NotifierService } from 'angular-notifier';
import { IdcExcelService } from './idc-excel.service';



@Component({
  selector: 'app-idc',
  templateUrl: './idc.component.html',
  styleUrls: ['./idc.component.scss']
})
export class IdcComponent implements OnInit {
  
  private readonly notifier: NotifierService;
 
  formValue !: FormGroup;
  idcAssets: any[] = [];
  images = [];
  components = [];

  newIdcAssetEvent = true;

  page: number = 1;
  totalRecords!: any;

  constructor(
    private idcAssetService: IdcAssetService,
    private router: Router,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private constantsService: ConstantsService,
    private notifierService: NotifierService,
    private idcExcelService: IdcExcelService
  ) {
    this.notifier = notifierService;
   }

  ngOnInit(): void {
    this.getIdcAssets();
    this.initializeForm();
  }

  initializeForm() {
    this.newIdcAssetEvent = true;
    this.formValue = this.formBuilder.group({
    barcode: new FormControl('', [Validators.required]),
    serialNumber: new FormControl('', [Validators.required]),
    assetDescription: new FormControl('', [Validators.required]),
    componentDescription: new FormControl('', [Validators.required]),
    companyName: new FormControl('', [Validators.required]),
    department: new FormControl('', [Validators.required]),
    location: new FormControl('', [Validators.required]),
    gpsLatitude: new FormControl('', [Validators.required]),
    gpsLongitude: new FormControl('', [Validators.required]),
    make: new FormControl('', [Validators.required]),
    custodian: new FormControl('', [Validators.required]),
    model: new FormControl('', [Validators.required]),
    manufacturer: new FormControl('', [Validators.required]),
    ComponentReference: new FormControl('', [Validators.required]),
    notes: new FormControl('', [Validators.required]),
    idcAssetGuid: new FormControl('', [Validators.required]),
    driveMoto: new FormControl('', [Validators.required]),
    equipmentNumber: new FormControl('', [Validators.required]),
    assetCondition: new FormControl('', [Validators.required]),
    sizeCapacity: new FormControl('', [Validators.required]),
    userGuidLastUpdated: new FormControl('', [Validators.required]),
    componentReference: new FormControl(''),
    verified: new FormControl('', [Validators.required]),
    syncGuid: new FormControl('', [Validators.required]),
  });
}

  getIdcAssets() {
    this.spinner.show(); // showing loader spinner
    this.idcAssetService.getAllIdcAsset().subscribe((results) => {
      console.log('assets', results);
      this.idcAssets = results;
      this.spinner.hide(); // closing loading spinner
    }, error => {
      this.spinner.hide(); // closing loading spinner
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator');
      }
    });
  }

  showNotification() {
    console.log('Notify clicked!!!');
    this.notifier.notify('warning', 'You are awesome! I mean it!');
  }

  addNewAsset() {
    this.spinner.show(); // showing loader spinner
    console.log(this.formValue.value);

    if (this.formValue.valid) {

      // JSON Data to be sent to the server
      const data = {
        'barcode': this.formValue.value.barcode,
        'serialNumber': this.formValue.value.serialNumber,
        'assetDescription': this.formValue.value.assetDescription,
        'componentDescription': this.formValue.value.componentDescription,
        'companyName': this.formValue.value.assetDescription,
        'department': this.formValue.value.assetDescription,
        'componentReference': this.formValue.value.componentReference,
        'custodian': this.formValue.value.custodian,
        'equipmentNumber': this.formValue.value.equipmentNumber,
        'location': this.formValue.value.location,
        'make': this.formValue.value.make,
        'manufacturer': this.formValue.value.manufacturer,
        'model': this.formValue.value.model,
        'notes': this.formValue.value.notes,
        'sizeCapacity': this.formValue.value.sizeCapacity,
        'verified': this.formValue.value.verified,
        'syncGuid': this.formValue.value.syncGuid,
        'userGuidUpdate': this.formValue.value.userGuidLastUpdated,
        'idcAssetGuid': this.formValue.value.idcAssetGuid,
      };

      // auto click close button
      const cancelRef = document.getElementById('cancel');

      this.idcAssetService.addIdcAsset(data).subscribe(results => {
        // console.log(results);
        this.spinner.hide();
        this.getIdcAssets(); // reload data on the screen
        cancelRef?.click();
        console.log(results);
        this.notifier.notify('success', results?.responseMessage);
      }, error => {
        this.spinner.hide();
        console.log(error);
        if (error.status === 401) {
          this.notifier.notify('error', 'You need to login again');
          this.router.navigateByUrl('login');
        } else if (error.status === 409) {
          this.notifier.notify('warning', 'Already exists');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator'
          );
        }
        cancelRef?.click();
        // this.spinner.hide();
      });

      this.initializeForm(); // clear form
    } else {
      this.spinner.hide();
      this.notifier.notify('error', 'Fill in all the form fields');
    }
  }

  saveChanges() {
    if (this.newIdcAssetEvent) {
      this.addNewAsset(); // add new role
    } else {
      this.updateIdcAsset(); // update existing role
    }
  }

  downloadReport() {
    this.idcExcelService.generateExcelFile(this.idcAssets);
  }


  updateIdcAsset() {
    this.spinner.show(); // showing loader spinner
    console.log(this.formValue.value);

    if (this.formValue.valid) {

      if (!confirm(`Are you sure you want to save these changes?`)) {
        this.spinner.hide();
        return;
      }

      // JSON Data to be sent to the server
      const data = {
        'barcode': this.formValue.value.barcode,
        'serialNumber': this.formValue.value.serialNumber,
        'assetDescription': this.formValue.value.assetDescription,
        'componentDescription': this.formValue.value.componentDescription,
        'companyName': this.formValue.value.assetDescription,
        'department': this.formValue.value.assetDescription,
        
      };
    

      // auto click close button
      const cancelRef = document.getElementById('cancel');

      this.idcAssetService.updateIdcAsset(data).subscribe(results => {
        // console.log(results);
        this.spinner.hide();
        this.getIdcAssets(); // reload data on the screen
        cancelRef?.click();
        console.log(results);
        this.notifier.notify('success', results?.responseMessage);
      }, error => {
        this.spinner.hide();
        console.log(error);
        if (error.status === 401) {
          this.notifier.notify('error', 'You need to login again');
          this.router.navigateByUrl('login');
        } else if (error.status === 409) {
          this.notifier.notify('warning', 'Already exists');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator'
          );
        }
        cancelRef?.click();
        // this.spinner.hide();
      });

      this.initializeForm(); // clear form
    } else {
      this.spinner.hide();
      console.log('Form not valid!!!!');
    }
  }

  // save changes, this method is called on save changes button
  openIdcAsset(asset: any) {
    this.images = [];
    this.components = [];
    console.log(asset);
    this.newIdcAssetEvent = false;
    this.formValue.reset();
    const btnAsset = document.getElementById('btnAsset');
    btnAsset.click();

    this.images = asset.images;
    this.components = asset.components;

    this.formValue.controls['barcode'].setValue(asset.primaryAsset.barcode);
    this.formValue.controls['serialNumber'].setValue(asset.primaryAsset.serialNumber);
    this.formValue.controls['assetDescription'].setValue(asset.primaryAsset.assetDescription);
    this.formValue.controls['componentDescription'].setValue(asset.primaryAsset.componentDescription);
    this.formValue.controls['companyName'].setValue(asset.primaryAsset.companyName);
    this.formValue.controls['department'].setValue(asset.primaryAsset.department);
    this.formValue.controls['location'].setValue(asset.primaryAsset.location);
    this.formValue.controls['componentReference'].setValue(asset.primaryAsset.componentReference);
    this.formValue.controls['custodian'].setValue(asset.primaryAsset.custodian);
    this.formValue.controls['driveMoto'].setValue(asset.primaryAsset.driveMoto);
    this.formValue.controls['equipmentNumber'].setValue(asset.primaryAsset.equipmentNumber);
    this.formValue.controls['make'].setValue(asset.primaryAsset.make);
    this.formValue.controls['manufacturer'].setValue(asset.primaryAsset.manufacturer);
    this.formValue.controls['model'].setValue(asset.primaryAsset.model);
    this.formValue.controls['notes'].setValue(asset.primaryAsset.notes);
    this.formValue.controls['sizeCapacity'].setValue(asset.primaryAsset.sizeCapacity);
    this.formValue.controls['syncGuid'].setValue(asset.primaryAsset.syncGuid);
    this.formValue.controls['idcAssetGuid'].setValue(asset.primaryAsset.idcAssetGuid);
    this.formValue.controls['verified'].setValue(asset.primaryAsset.verified);
    this.formValue.controls['userGuidLastUpdated'].setValue(asset.primaryAsset.userGuidLastUpdated);
  }
}
