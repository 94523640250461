import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from '../../../environments/environment';
import { ConstantsService } from '../constants/constants.service';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class AssetClassService {

  constructor(private http: HttpClient, private constants: ConstantsService) { }


searchClassAsset(field: any, value: any): Observable<any> {
  return this.http.get(`${baseUrl}/api/assetopti/config/asset-class/v1/search-asset-class-by-anything/${field}/${value}`, { headers: this.constants.getHeaders() });
  }

  // Get all 
getAllClassAssets(): Observable<any> {
  return this.http.get(`${baseUrl}/api/assetopti/config/asset-class/v1/list-all-asset-class`, { headers: this.constants.getHeaders() });
}

// Add 
addClassAsset(data: any): Observable<any> {
  return this.http.post(`${baseUrl}/api/assetopti/config/asset-class/v1/create-asset-class`, data, { headers: this.constants.getHeaders() });
}

// Update 
updateClassAsset(data: any): Observable<any> {
  return this.http.put(`${baseUrl}/api/assetopti/config/asset-class/v1/update-asset-class`, data, { headers: this.constants.getHeaders() });
}

// Delete 
deleteClassAsset(assetClassGuid: any): Observable<any> {
  return this.http.delete(`${baseUrl}/api/assetopti/config/asset-class/v1/delete-asset-class/${assetClassGuid}`, { headers: this.constants.getHeaders() });
}

}
