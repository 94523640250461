import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { DbsaAssetService } from '../../../services/assets/dbsa-asset.service';
import { ConstantsService } from '../../../services/constants/constants.service';
import { ClientsService } from '../../../services/account/clients.service';
import { DbsaExcelService } from './dbsa-excel.service';


@Component({
  selector: 'app-dbsa',
  templateUrl: './dbsa.component.html',
  styleUrls: ['./dbsa.component.scss']
})
export class DbsaComponent implements OnInit {

  private readonly notifier: NotifierService;

  dbsaAssets: any[] = [];
  newDbsaAssetEvent: boolean;
  formValue !: FormGroup;

  page: number = 1;
  totalRecords!: any;
  images = [];
  components: any[] = [];

  constructor(
    private dbsaAssetService:  DbsaAssetService,
    private router: Router,
    private formBuilder: FormBuilder,
    private clientsService: ClientsService,
    private spinner: NgxSpinnerService,
    private constantsService: ConstantsService,
    private notifierService: NotifierService,
    private dbsaExcelService: DbsaExcelService
  ) { 
    this.notifier = notifierService;
  }

  ngOnInit(): void {
    this.initForm();
    this.getDbsaAsset();
    
  }

  initForm() {
    this.newDbsaAssetEvent = true;
    this.formValue = this.formBuilder.group({
      barcode: new FormControl('', [Validators.required]),
      serialNumber: new FormControl('', [Validators.required]),
      assetDescription: new FormControl('', [Validators.required]),
      assetLocation: new FormControl('',[Validators.required]),
      department: new FormControl('',[Validators.required]),
      componentReference: new FormControl('', [Validators.required]),
      conditionComment: new FormControl('', [Validators.required]),
      custodian: new FormControl('', [Validators.required]),
      assetCondition: new FormControl('',[Validators.required]),
      sizeCapacity: new FormControl('',[Validators.required]),
      streetName: new FormControl('', [Validators.required]),
      townName: new FormControl('', [Validators.required]),
      operationalStatus: new FormControl('', [Validators.required]),
      gpsLatitude: new FormControl('', [Validators.required]),
      gpsLongitude: new FormControl('', [Validators.required]),
      syncGuid: new FormControl('', [Validators.required]),
      userGuidCreated: new FormControl('', [Validators.required]),
      userGuidUpdated: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      dbsaAssetGuid: new FormControl( ' ')

     
    });
  }

  getDbsaAsset() {
    this.spinner.show(); // showing loader spinner
    this.dbsaAssetService.getAllDbsaAsset().subscribe((results) => {
      console.log('DbsaAssets', results);
      this.dbsaAssets = results;
      this.spinner.hide(); // closing loading spinner
    }, error => {
      this.spinner.hide(); // closing loading spinner
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator');
      }
    });
  }


  addNewDbsaAsset() {
    this.spinner.show(); // showing loader spinner
    console.log(this.formValue.value);

    if (this.formValue.valid) {

      // JSON Data to be sent to the server
      const data = {
        'barcode': this.formValue.value.barcode,
        'serialNumber': this.formValue.value.serialNumber,
        'assetDescription': this.formValue.value.aasetDescription,
        'assetDepartment': this.formValue.value.department,
        'assetLocation': this.formValue.value.assetLocation,
        'componentReference': this.formValue.value.componentReference,
        'custodian': this.formValue.value.custodian,
        'assetCondition': this.formValue.value.assetCondition,
        'department': this.formValue.value.department,
        'conditionComment': this.formValue.value.conditionComment,
        'sizeCapacity': this.formValue.value.sizeCapacity,
        'streetName': this.formValue.value. streetName,
        'townName': this.formValue.value.townName,
        'operationalStatus': this.formValue.value.operationalStatus,
        'gpsLatitude': this.formValue.value.gpsLatitude,
        'gpsLongitude': this.formValue.value.gpsLongitude,
        'syncGuid': this.formValue.value.syncGuid,
        'userGuidUpdated': this.formValue.value.firstName,
        'userGuidCreated': this.formValue.value.firstName,
        'dbsaAssetGuid': this.formValue.value.dbsaAssetGuid

       
            
      };

      console.log(data);

      // auto click close button
      const cancelRef = document.getElementById('cancel');

      this.dbsaAssetService.addDbsaAsset(data).subscribe(results => {
        console.log(results);
        this.spinner.hide();
        this.getDbsaAsset(); // reload data on the screen
        cancelRef?.click();
        console.log(results);
        this.notifier.notify('success', results?.responseMessage);
      }, error => {
        this.spinner.hide();
        console.log(error);
        if (error.status === 401) {
          this.notifier.notify('error', 'You need to login again');
          this.router.navigateByUrl('login');
        } else if (error.status === 409) {
          this.notifier.notify('warning', 'Already exists');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator'
          );
        }
        cancelRef?.click();
        // this.spinner.hide();
      });

      this.initForm(); // clear form
    } else {
      this.spinner.hide();
      this.notifier.notify('error', 'Fill in all the form fields');
    }
  }

  showNotification() {
    console.log('Notify clicked!!!');
    this.notifier.notify('warning', 'You are awesome! I mean it!');
  }

  saveChanges() {
    if (this.newDbsaAssetEvent) {
      this.addNewDbsaAsset(); // add new role
    } else {
     // this.updateDbsaAsset(); // update existing role
    }
  }

  updateDbsaAsset() {
    this.spinner.show(); // showing loader spinner
    console.log(this.formValue.value);

    if (this.formValue.valid) {

      if (!confirm(`Are you sure you want to save these changes?`)) {
        this.spinner.hide();
        return;
      }

      // JSON Data to be sent to the server
      const data = {
        'barcode': this.formValue.value.barcode,
        'serialNumber': this.formValue.value.serialNumber,
        'assetDescription': this.formValue.value.assetDescription,
        'department': this.formValue.value.department,
        'townName': this.formValue.value.townName,
        'streetName': this.formValue.value.streetName,
        
      };
    

      // auto click close button
      const cancelRef = document.getElementById('cancel');

      this.dbsaAssetService.updateDbsaAsset(data).subscribe(results => {
        // console.log(results);
        this.spinner.hide();
        this.getDbsaAsset(); // reload data on the screen
        cancelRef?.click();
        console.log(results);
        this.notifier.notify('success', results?.responseMessage);
      }, error => {
        this.spinner.hide();
        console.log(error);
        if (error.status === 401) {
          this.notifier.notify('error', 'You need to login again');
          this.router.navigateByUrl('login');
        } else if (error.status === 409) {
          this.notifier.notify('warning', 'Already exists');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator'
          );
        }
        cancelRef?.click();
        // this.spinner.hide();
      });

      this.initForm(); // clear form
    } else {
      this.spinner.hide();
      console.log('Form not valid!!!!');
    }
  }

  downloadReport() {
    this.dbsaExcelService.generateExcelFile(this.dbsaAssets);
  }

  openDbsaAsset(dbsaAsset: any) {
    this.images = [];
    this.components = [];
    console.log(dbsaAsset);
    this.newDbsaAssetEvent
    this.formValue.reset();
    const btnDbsaAsset = document.getElementById('btnDbsaAsset');
    btnDbsaAsset.click();

   this.images = dbsaAsset.images;
   this.components = dbsaAsset.components;

    this.formValue.controls['barcode'].setValue(dbsaAsset.primaryAsset.barcode);
    this.formValue.controls['serialNumber'].setValue(dbsaAsset.primaryAsset.serialNumber);
    this.formValue.controls['assetDescription'].setValue(dbsaAsset.primaryAsset.assetDescription);
    this.formValue.controls['department'].setValue(dbsaAsset.primaryAsset.department);
    this.formValue.controls['townName'].setValue(dbsaAsset.primaryAsset.townName);
    this.formValue.controls['streetName'].setValue(dbsaAsset.primaryAsset.streetName);
    this.formValue.controls['assetLocation'].setValue(dbsaAsset.primaryAsset.assetLocation);
    this.formValue.controls['componentReference'].setValue(dbsaAsset.primaryAsset.componentReference);
    this.formValue.controls['dbsaAssetGuid'].setValue(dbsaAsset.primaryAsset.dbsaAssetGuid);
    this.formValue.controls['custodian'].setValue(dbsaAsset.primaryAsset.custodian);
    this.formValue.controls['assetCondition'].setValue(dbsaAsset.primaryAsset.assetCondition);
    this.formValue.controls['conditionComment'].setValue(dbsaAsset.primaryAsset.conditionComment);
    this.formValue.controls['sizeCapacity'].setValue(dbsaAsset.primaryAsset.sizeCapacity);
    this.formValue.controls['operationalStatus'].setValue(dbsaAsset.primaryAsset.operationalStatus);
    this.formValue.controls['gpsLatitude'].setValue(dbsaAsset.primaryAsset.gpsLatitude);
    this.formValue.controls['gpsLongitude'].setValue(dbsaAsset.primaryAsset.gpsLongitude);
    this.formValue.controls['syncGuid'].setValue(dbsaAsset.primaryAsset.syncGuid);
    this.formValue.controls['firstName'].setValue(dbsaAsset.primaryAsset.firstName);
    this.formValue.controls['firstName'].setValue(dbsaAsset.userCreated.firstName);
    
   

  }


}
