import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver'

@Injectable({
  providedIn: 'root'
})
export class IdcExcelService {

  // https://www.ngdevelop.tech/export-to-excel-in-angular-6/
  // https://www.tektutorialshub.com/angular/how-to-export-to-excel-in-angular/
  constructor() { }

  public generateExcelFile(data: any[]) {
    const title = 'List of assets';
    const headers = [
      'assetCondition',
      'assetDescription',
      'assetUseType',
      'barcode',
      'comments',
      'companyGuid',
      'companyName',
      'componentDescription',
      'componentReference',
      'custodian',
      'dateAdded',
      'dateUpdated',
      'deleted',
      'department',
      'driveMoto',
      'equipmentNumber',
      'gpsLatitude',
      'gpsLongitude',
      'idcAssetGuid',
      'idcAssetId',
      'location',
      'make',
      'manufacturer',
      'model',
      'notes',
      'serialNumber',
      'sizeCapacity',
      'syncGuid',
      'userGuidCreated',
      'userGuidLastUpdated',
      'verified',
      'piture'
    ];

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('report');
    const headerRow = worksheet.addRow(headers);

    for (let i in data) {
      let list = [];
      list.push(data[i].primaryAsset.assetCondition);
      list.push(data[i].primaryAsset.assetDescription);
      list.push(data[i].primaryAsset.assetUseType);
      list.push(data[i].primaryAsset.barcode);
      list.push(data[i].primaryAsset.comments);
      list.push(data[i].primaryAsset.companyGuid);
      list.push(data[i].primaryAsset.companyName);
      list.push(data[i].primaryAsset.componentDescription);
      list.push(data[i].primaryAsset.componentReference);
      list.push(data[i].primaryAsset.custodian);
      list.push(data[i].primaryAsset.dateAdded);
      list.push(data[i].primaryAsset.dateUpdated);
      list.push(data[i].primaryAsset.deleted);
      list.push(data[i].primaryAsset.department);
      list.push(data[i].primaryAsset.driveMoto);
      list.push(data[i].primaryAsset.equipmentNumber);
      list.push(data[i].primaryAsset.gpsLatitude);
      list.push(data[i].primaryAsset.gpsLongitude);
      list.push(data[i].primaryAsset.idcAssetGuid);
      list.push(data[i].primaryAsset.idcAssetId);
      list.push(data[i].primaryAsset.location);
      list.push(data[i].primaryAsset.make);
      list.push(data[i].primaryAsset.manufacturer);
      list.push(data[i].primaryAsset.model);
      list.push(data[i].primaryAsset.notes);
      list.push(data[i].primaryAsset.serialNumber);
      list.push(data[i].primaryAsset.sizeCapacity);
      list.push(data[i].primaryAsset.syncGuid);
      list.push(data[i].primaryAsset.userGuidCreated);
      list.push(data[i].primaryAsset.userGuidLastUpdated);
      list.push(data[i].primaryAsset.verified);
      
      for (let k in data[i].images) {
        list.push("https://zamambo.dedicated.co.za/mani-am/secret_asset_picture_kwadukuza.php?pic=" + data[i].images[k].fileName);
      }

      worksheet.addRow(list);
    }
    
    // worksheet.addRows(data);

    workbook.xlsx.writeBuffer().then((res) => {
      const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'IDCReport.xlsx');
    });
  }
}
