import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from '../../../environments/environment';
import { ConstantsService } from '../constants/constants.service';
import { TokenService } from '../constants/token.service';

@Injectable({
  providedIn: 'root'
})
export class WorkOrderService {

  constructor(private http: HttpClient, private tokenStorageService: TokenService, private constants: ConstantsService) { }

  // Get All 
  getAllOrders(): Observable<any> {
    return this.http.get(`${baseUrl}/api/assetopti/workorders/v1/list-all-work-order`, { headers: this.constants.getHeaders() });
  }
  
  // Add 
  addOrder(data: any): Observable<any> {
    return this.http.post(`${baseUrl}/api/assetopti/workorders/v1/create-work-order`, data, { headers: this.constants.getHeaders() });
  }

  // Update 
  updateOrder(data: any): Observable<any> {
    return this.http.put(`${baseUrl}/api/assetopti/workorders/v1/update-accounting-group`, data, { headers: this.constants.getHeaders() });
  }

  // Delete 
  deleteOrder(maniClientGuid: any): Observable<any> {
    return this.http.delete(`${baseUrl}/api/assetopti/workorders/v1/delete-work-order/${maniClientGuid}`, { headers: this.constants.getHeaders() });
  }

}
