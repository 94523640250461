import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
import jwt_decode from 'jwt-decode';

declare var  $: any;

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {

  emptyHeaders: any;
  headers!: any
  blobHeaders!: any;

  SUCCESS: string = "success";
  DANGER: string = "danger";
  WARNING: string = "warning";
  PRIMARY: string = "primary";
  INFO: string = "info";
  SECONDARY: string = "secondary";

  constructor(private tokenStorageService: TokenService) {
    this.reload();
  }

  reload() {
    this.headers = {
      Authorization: `Bearer ${this.tokenStorageService.getToken()}`,
      'Content-Type': 'application/json',
    }

    this.emptyHeaders = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
  }

  getHeaders() {
    this.reload();
    return this.headers;
  }

  getJwtClaims() {
    const decoded = jwt_decode(this.tokenStorageService.getToken());
    // console.log(decoded);
    return decoded;
  }

  notify(
    message: any,
    type: any
  ) {

    let icon = '';

    switch (type) {
      case this.SUCCESS:
        icon = 'feather icon-check-circle';
        break;
      case this.DANGER:
        icon = 'feather icon-x-circle';
        break;
      case this.WARNING:
        icon = 'feather icon-alert-triangle';
        break;
      case this.PRIMARY:
        icon = 'feather icon-circle';
        break;
      case this.INFO:
        icon = 'feather icon-info';
        break;
      default:
        icon = 'feather icon-eye';
        break;

    }

    $.notify(
      {
        icon: icon,
        title: '',
        message: message,
        url: '',
      },
      {
        element: 'body',
        type: type,
        allow_dismiss: true,
        placement: {
          from: 'top',
          align: 'right',
        },
        offset: {
          x: 30,
          y: 30,
        },
        spacing: 10,
        z_index: 999999,
        delay: 2500,
        timer: 1000,
        url_target: '_blank',
        mouse_over: false,
        animate: {
          enter: 'animated bounceIn',
          exit: 'animated flipOutX',
        },
        icon_type: 'class',
        template:
          '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
          '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
          '<span data-notify="icon"></span> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          '</div>' +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          '</div>',
      },
    )
  }
}
