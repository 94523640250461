import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from '../../../environments/environment';
import { ConstantsService } from '../constants/constants.service';
import { TokenService } from '../constants/token.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private constants: ConstantsService) { }

  // Get user profile
  getUserProfile(): Observable<any> {
    return this.http.get(`${baseUrl}/api/user/current-session-user-profile`, { headers: this.constants.getHeaders() });
  }

  // Check if sesssion is still valid
  checkUsersession(): Observable<any> {
    return this.http.get(`${baseUrl}/api/user/check-session`, { headers: this.constants.getHeaders() });
  }

  // Get all users
  getAllUsers(): Observable<any> {
    return this.http.get(`${baseUrl}/api/assetopti/user/v1/list-all-user`, { headers: this.constants.getHeaders() });
  }

  // Add User
  addUser(data: any): Observable<any> {
    return this.http.post(`${baseUrl}/api/assetopti/user/v1/add-user`, data, { headers: this.constants.getHeaders() });
  }

  // change user password
  changeUserPassword(data: any): Observable<any> {
    return this.http.put(`${baseUrl}/api/assetopti/user/v1/change-user-password`, data, { headers: this.constants.getHeaders() });
  }

  // Update User
  updateUser(data: any): Observable<any> {
    return this.http.put(`${baseUrl}/api/assetopti/user/v1/update-user`, data, { headers: this.constants.getHeaders() });
  }

  // Delete User
  deleteUser(userGuid: any): Observable<any> {
    return this.http.delete(`${baseUrl}/api/assetopti/user/v1/delete-user/${userGuid}`, { headers: this.constants.getHeaders() });
  }

}
