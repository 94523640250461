import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConstantsService } from '../../../services/constants/constants.service';
import { WorkOrderService} from '../../../services/account/work-order.service';



@Component({
  selector: 'app-work-order-list',
  templateUrl: './work-order-list.component.html',
  styleUrls: ['./work-order-list.component.scss']
})
export class WorkOrderListComponent implements OnInit {
  formValue: FormGroup;
  newWorkOrderEvent: boolean;
  orders: any;
  notifier: any;

  constructor(
   
    private workOrderService: WorkOrderService,
    private router: Router,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private constantsService: ConstantsService,
    private notifierService: NotifierService
  ) {
    this.notifier = notifierService;
   }

  ngOnInit(): void {
    this.initForm();
    this.getOrders();
    
  }

  initForm() {
    this.newWorkOrderEvent = true;
    this.formValue = this.formBuilder.group({
      reference: new FormControl(''),
      equipmentName: new FormControl(''),
      Type: new FormControl(''),
      assignedTo: new FormControl(''),
      assignedBy: new FormControl(''),
      status: new FormControl(''),
      retailerName: new FormControl('', [Validators.required]),
      technicalUserGuid: new FormControl(''),
      equipment: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      priority: new FormControl('', [Validators.required]),
      clientid: new FormControl(''),
      workOrderGuid: new FormControl(''),
      syncGuid: new FormControl(''),
      userGuidCreated: new FormControl(''),
      userGuidUpdated: new FormControl(''),
      technician: new FormControl('', [Validators.required]),
      dueDate: new FormControl( ' ')

     
    });
  }

  getOrders() {
    this.spinner.show(); // showing loader spinner
    this.workOrderService.getAllOrders().subscribe((results) => {
      console.log('Orders', results);
      this.orders = results;
      this.spinner.hide(); // closing loading spinner
    }, error => {
      this.spinner.hide(); // closing loading spinner
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator');
      }
    });
  }


  addNewOrder() {
    this.spinner.show(); // showing loader spinner
    console.log(this.formValue.value);

    if (this.formValue.valid) {

      // JSON Data to be sent to the server
      console.log(this.formValue.value.dueDate);
      const data = {
        'reference': this.formValue.value.reference,
        'equipmentName': this.formValue.value.equipmentName,
        'Type': this.formValue.value.Type,
        'assignedTo': this.formValue.value.assignedTo,
        'assignedBy': this.formValue.value.assignedBy,
        'retailerName': this.formValue.value.retailerName,
        'status': 'open',
        'technician': this.formValue.value.technician,
        'technicalUserGuid': this.formValue.value.technicalUserGuid,
        'equipment': this.formValue.value.equipment,
        'description': this.formValue.value.description,
        'priority': this.formValue.value.priority,
        'clientid': this.formValue.value.clientid,
        'workOrderGuid': this.formValue.value.workOrderGuid,
        'syncGuid': this.formValue.value.syncGuid,
        'dueDate': `${this.formValue.value.dueDate}T00:00:00.01`
      };

      console.log(data);

      // auto click close button
      const cancelRef = document.getElementById('cancel');

      this.workOrderService.addOrder(data).subscribe(results => {
        console.log(results);
        this.spinner.hide();
        this.getOrders(); // reload data on the screen
        cancelRef?.click();
        console.log(results);
        this.notifier.notify('success', results?.responseMessage);
      }, error => {
        this.spinner.hide();
        console.log(error);
        if (error.status === 401) {
          this.notifier.notify('error', 'You need to login again');
          this.router.navigateByUrl('login');
        } else if (error.status === 409) {
          this.notifier.notify('warning', 'Already exists');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator'
          );
        }
        cancelRef?.click();
        // this.spinner.hide();
      });

      this.initForm(); // clear form
    } else {
      this.spinner.hide();
      this.notifier.notify('error', 'Fill in all the form fields');
    }
  }

  showNotification() {
    console.log('Notify clicked!!!');
    this.notifier.notify('warning', 'You are awesome! I mean it!');
  }

  saveChanges() {
    if (this.newWorkOrderEvent) {
      this.addNewOrder(); // add new order
    } else {
     // this.updateOrder(); // update existing order
    }
  }

  openOrder(order: any) {
    console.log(order);
    this.formValue.reset();
    const btnOrder = document.getElementById('btnOrder');
    btnOrder.click();


    this.formValue.controls['reference'].setValue(order.reference);
    this.formValue.controls['equipmentName'].setValue(order.equipmentName);
    this.formValue.controls['Type'].setValue(order.Type);
    this.formValue.controls['assignedTo'].setValue(order.assignedTo);
    this.formValue.controls['assignedBy'].setValue(order.assignedBy);
    this.formValue.controls['retailerName'].setValue(order.retailerName);
    this.formValue.controls['status'].setValue(order.status);
    this.formValue.controls['technician'].setValue(order.technician);
    this.formValue.controls['technicalUserGuid'].setValue(order.technicalUserGuid);
    this.formValue.controls['equipment'].setValue(order.equipment);
    this.formValue.controls['description'].setValue(order.description);
    this.formValue.controls['priority'].setValue(order.priority);
    this.formValue.controls['clientid'].setValue(order.clientid);
    this.formValue.controls['workOrderGuid'].setValue(order.workOrderGuid);
    this.formValue.controls['syncGuid'].setValue(order.syncGuid);
    this.formValue.controls['dueDate'].setValue(order.dueDate);
  }



}
