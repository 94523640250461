import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { baseUrl } from '../../../environments/environment';
import { ConstantsService } from '../constants/constants.service';
import { TokenService } from '../constants/token.service';

@Injectable({
  providedIn: 'root'
})
export class IdcAssetService {

  constructor(private http: HttpClient, private tokenStorageService: TokenService, private constants: ConstantsService) {}

   // Get All 
   getAllIdcAsset(): Observable<any> {
    return this.http.get(`${baseUrl}/api/assetopti/idc/asset/v1/list-idcAsset`, { headers: this.constants.getHeaders() });
  }
  
  //Add 
  addIdcAsset(data: any): Observable<any> {
   return this.http.post(`${baseUrl}/api/assetopti/idc/asset/v1/createIdcAsset`, data, { headers: this.constants.getHeaders() });
  }

  // Update 
  updateIdcAsset(data: any): Observable<any> {
   return this.http.put(`${baseUrl}/api/assetopti/idc/asset/v1/update-idcAsset`, data, { headers: this.constants.getHeaders() });
  }

   //Delete 
  deleteIdcAsset(maniClientGuid: any): Observable<any> {
  return this.http.delete(`${baseUrl}/api/assetopti/idc/asset/v1/delete-idcAsset/${maniClientGuid}`, { headers: this.constants.getHeaders() });
  }



}
