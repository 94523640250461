import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccSubGroupService } from '../../../../services/configs/acc-sub-group.service';
import { ConstantsService } from '../../../../services/constants/constants.service';
import { ClientsService } from '../../../../services/account/clients.service';
import {AccountingGroupService } from '../../../../services/configs/accounting-group.service';

@Component({
  selector: 'app-accounting-sub-group',
  templateUrl: './accounting-sub-group.component.html',
  styleUrls: ['./accounting-sub-group.component.scss']
})
export class AccountingSubGroupComponent implements OnInit {

  notifier: any;
  newAccSubGroupEvent: boolean;
  formValue: any;
  clients: any;
  SubGroups: any;
  groups: any;

  

  constructor(
    private  accSubGroupService:  AccSubGroupService,
    private router: Router,
    private formBuilder: FormBuilder,
    private clientsService: ClientsService,
    private accountingGroupService: AccountingGroupService,
    private spinner: NgxSpinnerService,
    private constantsService: ConstantsService,
    private notifierService: NotifierService
  ) {
        this.notifier = notifierService;
   }

   ngOnInit(): void {
    this.initForm();
    this.getSubGroup();
    this.getClients();
    
  }
  
  

  initForm() {
    this.newAccSubGroupEvent = true;
    this.formValue = this.formBuilder.group({
      accSubGroupName: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      company: new FormControl('', [Validators.required]),
      accountingGroup: new FormControl('', [Validators.required]),
      accSubGroupGuid: new FormControl(''),

      
    
    });
  }
  getSubGroup() {
    this.spinner.show(); // showing loader spinner
    this.accSubGroupService.getAllSubGroup().subscribe((results) => {
      console.log('SubGroups', results);
      this.SubGroups = results;
      this.spinner.hide(); // closing loading spinner
    }, error => {
      this.spinner.hide(); // closing loading spinner
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator');
      }
    });
  }

  getClients() {
    this.spinner.show(); // showing loader spinner
    this.clientsService.getAllClients().subscribe((results) => {
      console.log('Clients', results);
      this.clients = results;
      this.spinner.hide(); // closing loading spinner
    }, error => {
      this.spinner.hide(); // closing loading spinner
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator');
      }
    });
  }
  getGroup(value: any) {
    this.spinner.show(); // showing loader spinner
    this.accountingGroupService.searchAccountingGroup('maniClientGuid', value).subscribe((results) => {
      console.log('Groups', results);
      this.groups = results;
      this.spinner.hide(); // closing loading spinner
    }, error => {
      this.spinner.hide(); // closing loading spinner
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator');
      }
    });
  }

  // this method is called when ever the client drop down changes
  // If i
  onCompanyChange(value: any){
    console.log(value);
    this.getGroup(value);
  }


  addNewSubGroup() {
    this.spinner.show(); // showing loader spinner
    console.log(this.formValue.value);

    if (this.formValue.valid) {

      // JSON Data to be sent to the server
      const data = {
        'accSubGroupName': this.formValue.value.accSubGroupName,
        'description': this.formValue.value.description,
        'maniClientGuid': this.formValue.value.company,
        'accountingGroupGuid': this.formValue.value.accountingGroup,
        'accSubGroupGuid': this.formValue.value.accSubGroupGuid
        
      };

      // auto click close button
      const cancelRef = document.getElementById('cancel');

      this.accSubGroupService.addSubGroup(data).subscribe(results => {
        console.log(results);
        this.spinner.hide();
        this.getSubGroup(); // reload data on the screen
        cancelRef?.click();
        console.log(results);
        this.notifier.notify('success', results?.responseMessage);
      }, error => {
        this.spinner.hide();
        console.log(error);
        if (error.status === 401) {
          this.notifier.notify('error', 'You need to login again');
          this.router.navigateByUrl('login');
        } else if (error.status === 409) {
          this.notifier.notify('warning', 'Already exists');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator'
          );
        }
        cancelRef?.click();
        // this.spinner.hide();
      });

      this.initForm(); // clear form
    } else {
      this.spinner.hide();
      this.notifier.notify('error', 'Fill in all the form fields');
    }
  }

  updateSubGroup() {
    this.spinner.show(); // showing loader spinner
    console.log(this.formValue.value);

    if (this.formValue.valid) {

      if (!confirm(`Are you sure you want to save these changes?`)) {
        this.spinner.hide();
        return;
      }

      // JSON Data to be sent to the server
      const data = {
        
        'accSubGroupName': this.formValue.value.accSubGroupName,
        'description': this.formValue.value.description,
        'maniClientGuid': this.formValue.value.company,
        'accountingGroupGuid': this.formValue.value.accountingGroup,
        'accSubGroupGuid': this.formValue.value.accSubGroupGuid
        
        
      };

      // auto click close button
      const cancelRef = document.getElementById('cancel');

      this.accSubGroupService.updateSubGroup(data).subscribe(results => {
        // console.log(results);
        this.spinner.hide();
        this.getSubGroup(); // reload data on the screen
        cancelRef?.click();
        console.log(results);
        this.notifier.notify('success', results?.responseMessage);
      }, error => {
        this.spinner.hide();
        console.log(error);
        if (error.status === 401) {
          this.notifier.notify('error', 'You need to login again');
          this.router.navigateByUrl('login');
        } else if (error.status === 409) {
          this.notifier.notify('warning', 'Already exists');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator'
          );
        }
        cancelRef?.click();
        // this.spinner.hide();
      });

      this.initForm(); // clear form
    } else {
      this.spinner.hide();
      console.log('Form not valid!!!!');
    }
  }
  
  saveChanges() {
    if (this.newAccSubGroupEvent) {
      this.addNewSubGroup(); // add new role
    } else {
      this.updateSubGroup(); // update existing role
    }
  }


  
  openSubGroup(subGroup: any) {
    console.log(subGroup);
    this.newAccSubGroupEvent = false;
    this.formValue.reset();
    const btnSubGroup = document.getElementById('btnSubGroup');
    btnSubGroup.click();

   
    this.formValue.controls['accSubGroupName'].setValue(subGroup.accSubGroupName);
    this.formValue.controls['description'].setValue(subGroup.description);
    this.formValue.controls['company'].setValue(subGroup.maniClientGuid);
    this.formValue.controls['accountingGroup'].setValue(subGroup.accountingGroupGuid);
    this.formValue.controls['accSubGroupGuid'].setValue(subGroup.accSubGroupGuid);
  }


  deleteSubGroup(subGroup: any) {
    this.spinner.show(); // showing loader spinner

    if (!confirm(`Are you sure you want to delete this group (${subGroup.accSubGroupName})?`)) {
      this.spinner.hide();
      return;
    }

    // auto click close button
    const cancelRef = document.getElementById('cancel');

    this.accSubGroupService.deleteSubGroup(subGroup.accSubGroupGuid).subscribe(results => {
      // console.log(results);
      this.spinner.hide();
      this.getSubGroup(); // reload data on the screen
      cancelRef?.click();
      console.log(results);
      this.notifier.notify('success', results?.responseMessage);
    }, error => {
      this.spinner.hide();
      console.log(error);
      if (error.status === 401) {
        this.notifier.notify('error', 'You need to login again');
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator'
        );
      }
      cancelRef?.click();
      // this.spinner.hide();
    });
  }

}
