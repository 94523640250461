import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { AssetClassService } from '../../../../services/configs/asset-class.service';
import { ConstantsService } from '../../../../services/constants/constants.service';
import { ClientsService } from '../../../../services/account/clients.service';
import { AccSubGroupService} from '../../../../services/configs/acc-sub-group.service';
import { AccountingGroupService } from '../../../../services/configs/accounting-group.service';

@Component({
  selector: 'app-asset-class',
  templateUrl: './asset-class.component.html',
  styleUrls: ['./asset-class.component.scss']
})
export class AssetClassComponent implements OnInit {
  formValue: any;
  newAssetClassEvent: boolean;
  notifier: any;
  classAssets: any;
  clients: any;
  SubGroups: any;

  groups: any;

  constructor(
    private  assetClassService:  AssetClassService,
    private router: Router,
    private formBuilder: FormBuilder,
    private clientsService: ClientsService,
    private accountingGroupService: AccountingGroupService,
    private accSubGroupService: AccSubGroupService,
    private spinner: NgxSpinnerService,
    private constantsService: ConstantsService,
    private notifierService: NotifierService
  ) {
    this.notifier = notifierService;
   }


  ngOnInit(): void {
    this.initForm();
    this.getClassAssets();
    this.getClients();
    
  }
  
  

  initForm() {
    this.newAssetClassEvent = true;
    this.formValue = this.formBuilder.group({
      assetClassName: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      company: new FormControl('', [Validators.required]),
      accountingSubGroup: new FormControl('', [Validators.required]),
      accountingGroup: new FormControl('', [Validators.required]),
      assetClassGuid: new FormControl(''),
      
    
    });
  }
  getClassAssets() {
    this.spinner.show(); // showing loader spinner
    this.assetClassService.getAllClassAssets().subscribe((results) => {
      console.log('ClassAssets', results);
      this.classAssets = results;
      this.spinner.hide(); // closing loading spinner
    }, error => {
      this.spinner.hide(); // closing loading spinner
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator');
      }
    });
  }

  getClients() {
    this.spinner.show(); // showing loader spinner
    this.clientsService.getAllClients().subscribe((results) => {
      console.log('Clients', results);
      this.clients = results;
      this.spinner.hide(); // closing loading spinner
    }, error => {
      this.spinner.hide(); // closing loading spinner
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator');
      }
    });
  }

  getGroup(value: any) {
    this.spinner.show(); // showing loader spinner
    this.accountingGroupService.searchAccountingGroup('maniClientGuid', value).subscribe((results) => {
      console.log('Groups', results);
      this.groups = results;
      this.spinner.hide(); // closing loading spinner
    }, error => {
      this.spinner.hide(); // closing loading spinner
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator');
      }
    });
  }


  getSubGroup(value: any) {
    this.spinner.show(); // showing loader spinner
    this.accSubGroupService.searchAccSubGroup('maniClientGuid', value).subscribe((results) => {
      console.log('SubGroups', results);
      this.SubGroups = results;
      this.spinner.hide(); // closing loading spinner
    }, error => {
      this.spinner.hide(); // closing loading spinner
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator');
      }
    });
  }

  // this method is called when ever the client drop down changes
  // If i
  onCompanyChange(value: any){
    console.log(value);
    this.getSubGroup(value);
    this.getGroup(value);
  }


  addNewClassAsset() {
    this.spinner.show(); // showing loader spinner
    console.log(this.formValue.value);

    if (this.formValue.valid) {

      // JSON Data to be sent to the server
      const data = {
        'assetClassName': this.formValue.value.assetClassName,
        'description': this.formValue.value.description,
        'maniClientGuid': this.formValue.value.company,
        'accSubGroupGuid': this.formValue.value.accountingSubGroup,
        'accountingGroupGuid': this.formValue.value.accountingGroup,
        'assetClassGuid': this.formValue.value.assetClassGuid

        
      };

      // auto click close button
      const cancelRef = document.getElementById('cancel');

      this.assetClassService.addClassAsset(data).subscribe(results => {
        console.log(results);
        this.spinner.hide();
        this.getClassAssets(); // reload data on the screen
        cancelRef?.click();
        console.log(results);
        this.notifier.notify('success', results?.responseMessage);
      }, error => {
        this.spinner.hide();
        console.log(error);
        if (error.status === 401) {
          this.notifier.notify('error', 'You need to login again');
          this.router.navigateByUrl('login');
        } else if (error.status === 409) {
          this.notifier.notify('warning', 'Already exists');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator'
          );
        }
        cancelRef?.click();
        // this.spinner.hide();
      });

      this.initForm(); // clear form
    } else {
      this.spinner.hide();
      this.notifier.notify('error', 'Fill in all the form fields');
    }
  }

  updateClassAsset() {
    this.spinner.show(); // showing loader spinner
    console.log(this.formValue.value);

    if (this.formValue.valid) {

      if (!confirm(`Are you sure you want to save these changes?`)) {
        this.spinner.hide();
        return;
      }

      // JSON Data to be sent to the server
      const data = {
       'assetClasseName': this.formValue.value.assetClassName,
       'description': this.formValue.value.description,
       'maniClientGuid': this.formValue.value.company,
       'accSubGroupGuid': this.formValue.value.accountingSubGroup,
       'accountingGroupGuid': this.formValue.value.accountingGroupGuid,
       'assetClassGuid': this.formValue.value.assetClassGuid
      };

      // auto click close button
      const cancelRef = document.getElementById('cancel');

      this.assetClassService.updateClassAsset(data).subscribe(results => {
        // console.log(results);
        this.spinner.hide();
        this.getClassAssets(); // reload data on the screen
        cancelRef?.click();
        console.log(results);
        this.notifier.notify('success', results?.responseMessage);
      }, error => {
        this.spinner.hide();
        console.log(error);
        if (error.status === 401) {
          this.notifier.notify('error', 'You need to login again');
          this.router.navigateByUrl('login');
        } else if (error.status === 409) {
          this.notifier.notify('warning', 'Already exists');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator'
          );
        }
        cancelRef?.click();
        // this.spinner.hide();
      });

      this.initForm(); // clear form
    } else {
      this.spinner.hide();
      console.log('Form not valid!!!!');
    }
  }
  
  saveChanges() {
    if (this.newAssetClassEvent) {
      this.addNewClassAsset(); // add new role
    } else {
      this.updateClassAsset(); // update existing role
    }
  }


  
  openClassAsset(classAsset: any) {
    console.log(classAsset);
    this.newAssetClassEvent = false;
    this.formValue.reset();
    const btnClassAsset = document.getElementById('btnClassAsset');
    btnClassAsset.click();

    this.formValue.controls['assetClassName'].setValue(classAsset.assetClassName);
    this.formValue.controls['description'].setValue(classAsset.description);
    this.formValue.controls['company'].setValue(classAsset.maniClientGuid);
    this.formValue.controls['accountingSubGroup'].setValue(classAsset.accSubGroupGuid);
    this.formValue.controls['accountingGroup'].setValue(classAsset.accountingGroupGuid);
    this.formValue.controls['assetClassGuid'].setValue(classAsset.assetClassGuid);
  }


  deleteClassAsset(classAsset: any) {
    this.spinner.show(); // showing loader spinner

    if (!confirm(`Are you sure you want to delete this group (${classAsset.assetClassName})?`)) {
      this.spinner.hide();
      return;
    }

    // auto click close button
    const cancelRef = document.getElementById('cancel');

    this.assetClassService.deleteClassAsset(classAsset.assetClassGuid).subscribe(results => {
      // console.log(results);
      this.spinner.hide();
      this.getClassAssets(); // reload data on the screen
      cancelRef?.click();
      console.log(results);
      this.notifier.notify('success', results?.responseMessage);
    }, error => {
      this.spinner.hide();
      console.log(error);
      if (error.status === 401) {
        this.notifier.notify('error', 'You need to login again');
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator'
        );
      }
      cancelRef?.click();
      // this.spinner.hide();
    });
  }

}
