import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { IconModule, IconSetModule, IconSetService } from '@coreui/icons-angular';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';

import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

import { NotifierModule } from 'angular-notifier';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { RolesComponent } from './views/administration/roles/roles.component';
import { ClientsComponent } from './views/clients/clients.component';
import { ManiClientsComponent } from './views/administration/mani-clients/mani-clients.component';
import { ManiUsersComponent } from './views/administration/mani-users/mani-users.component';
import { AccountingGroupComponent } from './views/administration/configs/accounting-group/accounting-group.component';
import { AccountingSubGroupComponent } from './views/administration/configs/accounting-sub-group/accounting-sub-group.component';
import { AssetClassComponent } from './views/administration/configs/asset-class/asset-class.component';
import { AssetGroupTypeComponent } from './views/administration/configs/asset-group-type/asset-group-type.component';
import { AssetDescriptionComponent } from './views/administration/configs/asset-description/asset-description.component';
import { ComponentTypeComponent } from './views/administration/configs/component-type/component-type.component';
import { IdcComponent } from './views/fixed-asset-projects/idc-project/idc/idc.component';
import { DbsaComponent } from './views/fixed-asset-projects/dbsa/dbsa.component';
import { CogtaComponent } from './views/fixed-asset-projects/cogta/cogta.component';
import { WorkOrderListComponent} from './views/work-orders/work-order-list/work-order-list.component';


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    IconModule,
    IconSetModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    FormsModule,
    NgxPaginationModule,
    HttpClientModule,
    NgxSpinnerModule,
    CommonModule,
    NotifierModule

  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    RolesComponent,
    ClientsComponent,
    ManiClientsComponent,
    ManiUsersComponent,
    AccountingGroupComponent,
    AccountingSubGroupComponent,
    AssetClassComponent,
    AssetGroupTypeComponent,
    AssetDescriptionComponent,
    ComponentTypeComponent,
    IdcComponent,
    DbsaComponent,
    CogtaComponent,
    WorkOrderListComponent
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    IconSetService,
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
