import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    title: true,
    name: 'MANI Administrator'
  },
  {
    name: 'Administration',
    // url: '/users',
    icon: 'cil-building',
    children: [
      {
        name: 'Users',
        url: '/users',
        icon: 'icon-people'
      },
      {
        name: 'Roles',
        url: '/roles',
        icon: 'icon-people'
      },
      {
        name: 'Clients',
        url: '/mani-clients',
        icon: 'icon-people'
      },
      {
        name: 'Configurations',
        url: '/configs',
        icon: 'icon-people',
        children: [
          {
            name: 'Acc Groups',
            url: '/accounting-group',
            icon: 'icon-people'
          },
          {
            name: 'Acc Sub Groups',
            url: '/accounting-sub-group',
            icon: 'icon-people'
          },
          {
            name: 'Asset Classs',
            url: '/asset-class',
            icon: 'icon-people'
          },
          {
            name: 'Asset Group Type',
            url: '/asset-group-type',
            icon: 'icon-people'
          },
          {
            name: 'Asset Description',
            url: '/asset-description',
            icon: 'icon-people'
          },
          {
            name: 'Component Type',
            url: '/component-type',
            icon: 'icon-people'
          }
        ]
      }
    ]
  },
  {
    name: 'F.A.R Projects',
    url: '/projects',
    icon: 'icon-people',
    children: [
      {
        name: 'Cogta',
        url: '/cogta',
        icon: 'icon-people'
      },
      {
        name: 'Kwadukuza (KDM)',
        url: '/loyalty-points',
        icon: 'icon-basket-loaded'
      },
      {
        name: 'Dube Tradeport',
        url: '/dube',
        icon: 'icon-present'
      },
      {
        name: 'DBSA',
        url: '/dbsa',
        icon: 'icon-present',
        children: [
          {
            name: 'DBSA Dashboard',
            url: '/dbsa-dashboard',
            icon: 'icon-present'
          },
          {
            name: 'DBSA Asset List',
            url: '/dbsa',
            icon: 'icon-present'
          }
        ]
      },
      {
        name: 'IDC',
        url: '/idc',
        icon: 'icon-present',
        children: [
          {
            name: 'IDC Dashboard',
            url: '/idc-dashboard',
            icon: 'icon-present'
          },
          {
            name: 'IDC Asset List',
            url: '/idc',
            icon: 'icon-present'
          }
        ]
      }
    ]
  },
  {
    name: 'Work Orders',
    url: '/work-orders',
    icon: 'icon-people',
    children: [
      {
        name: 'Dashboard',
        url: '/work-order-dashboard',
        icon: 'icon-people'
      },
      {
        name: 'Work Orders',
        url: '/work-order-list',
        icon: 'icon-basket-loaded'
      }
    ]
  }/*,
  {
    name: 'Project Finances',
    url: '/clients',
    icon: 'cil-money',
    children: [
      {
        name: 'Expenses',
        url: '/clients',
        icon: 'cil-credit-card'
      },
      {
        name: 'Billing',
        url: '/clients',
        icon: 'cil-credit-card'
      },
      {
        name: 'Credit Accounts',
        url: '/clients',
        icon: 'cil-credit-card'
      },
      {
        name: 'Commission',
        url: '/commission',
        icon: 'icon-pie-chart'
      },
      {
        name: 'Book Keeping',
        url: '/commission',
        icon: 'icon-pie-chart'
      },
      {
        name: 'Reports',
        url: '/commission',
        icon: 'icon-pie-chart'
      }
    ]
  },
  {
    name: 'Inventory',
    url: '/clients',
    icon: 'cil-cart',
    children: [
      {
        name: 'Products',
        url: '/clients',
        icon: 'cil-cart'
      }
    ]
  },
  {
    name: 'Calendar',
    url: '/products',
    icon: 'cil-calendar'
  },
  {
    title: true,
    name: 'More'
  },
  {
    name: 'Help',
    url: '/widgets',
    icon: 'icon-info'
  }*/
];
