import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { baseUrl } from '../../../environments/environment';
import { ConstantsService } from '../constants/constants.service';
import { TokenService } from '../constants/token.service';


@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  
  constructor(private http: HttpClient, private tokenStorageService: TokenService, private constants: ConstantsService) { }

  // Get All 
  getAllClients(): Observable<any> {
    return this.http.get(`${baseUrl}/api/assetopti/idc/mani-clients/v1/list-all-mani-client`, { headers: this.constants.getHeaders() });
  }
  
  // Add 
  addClient(data: any): Observable<any> {
    return this.http.post(`${baseUrl}/api/assetopti/idc/mani-clients/v1/add-mani-client`, data, { headers: this.constants.getHeaders() });
  }

  // Update 
  updateClient(data: any): Observable<any> {
    return this.http.put(`${baseUrl}/api/assetopti/idc/mani-clients/v1/update-maniClient`, data, { headers: this.constants.getHeaders() });
  }

  // Delete 
  deleteClient(maniClientGuid: any): Observable<any> {
    return this.http.delete(`${baseUrl}/api/assetopti/idc/mani-clients/v1/delete-mani-client/${maniClientGuid}`, { headers: this.constants.getHeaders() });
  }

}
