import { Component } from '@angular/core';
import { DatePipe } from '@angular/common';

import Clients from './Clients';

@Component({
  templateUrl: 'clients.component.html',
  providers: [DatePipe]
})
export class ClientsComponent {

  constructor(public datepipe: DatePipe) { }
  pipe = new DatePipe('af-ZA');
  clients: Clients[] = [
    {
      full_name   : 'Mduduzi Mdluli',
      id_number   : '9807036759876',
      phone       : '0845880976',
      date_created: new Date(),
      status      : 'Active'
    },
    {
      full_name   : 'Nhlnhla Mkhize',
      id_number   : '9807036759876',
      phone       : '0845880976',
      date_created: new Date(),
      status      : 'Acive'
    },
    {
      full_name   : 'Senele Khulekani',
      id_number   : '9507036759085',
      phone       : '0845880976',
      date_created: new Date(),
      status      : 'Active'
    },
     {
      full_name   : 'Sibongiseni Ndebele',
      id_number   : '9103026759085',
      phone       : '0845880976',
      date_created: new Date(),
      status      : 'Active'
    },
  ];

}
