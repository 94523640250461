import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RolesService } from '../../../services/account/roles.service';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConstantsService } from '../../../services/constants/constants.service';

import { NotifierService } from 'angular-notifier';


declare var $: any;

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  roles: any[] = [];
  formValue !: FormGroup;
  private readonly notifier: NotifierService;

  newRoleEvent = true;

  constructor(
    private roleService: RolesService,
    private router: Router,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private constantsService: ConstantsService,
    private notifierService: NotifierService
    ) {
      this.notifier = notifierService;
    }

  // This method always run whenever you reload the page
  ngOnInit(): void {
    this.getRoles();
    this.initializeForm();
  }

  // Initialize form
  initializeForm() {
    this.newRoleEvent = true;
    this.formValue = this.formBuilder.group({
      roleName: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      roleGuid: new FormControl(''),
    });
  }

  // Get all roles from the Role service
  getRoles() {
    this.spinner.show(); // showing loader spinner
    this.roleService.getAllRoles().subscribe((results) => {
      console.log('Roles', results);
      this.roles = results;
      this.spinner.hide(); // closing loading spinner
    }, error => {
      this.spinner.hide(); // closing loading spinner
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator');
      }
    });
  }

  // testing
  showNotification() {
    console.log('Notify clicked!!!');
    this.notifier.notify('warning', 'You are awesome! I mean it!');
  }

  // Add News Role
  addNewRole() {
    this.spinner.show(); // showing loader spinner
    console.log(this.formValue.value);

    if (this.formValue.valid) {

      // JSON Data to be sent to the server
      const data = {
        'roleName': this.formValue.value.roleName,
        'description': this.formValue.value.description
      };

      // auto click close button
      const cancelRef = document.getElementById('cancel');

      this.roleService.addRole(data).subscribe(results => {
        // console.log(results);
        this.spinner.hide();
        this.getRoles(); // reload data on the screen
        cancelRef?.click();
        console.log(results);
        this.notifier.notify('success', results?.responseMessage);
      }, error => {
        this.spinner.hide();
        console.log(error);
        if (error.status === 401) {
          this.notifier.notify('error', 'You need to login again');
          this.router.navigateByUrl('login');
        } else if (error.status === 409) {
          this.notifier.notify('warning', 'Already exists');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator'
          );
        }
        cancelRef?.click();
        // this.spinner.hide();
      });

      this.initializeForm(); // clear form
    } else {
      this.spinner.hide();
      this.notifier.notify('error', 'Fill in all the form fields');
    }
  }

  // Update Role
  updateRole() {
    this.spinner.show(); // showing loader spinner
    console.log(this.formValue.value);

    if (this.formValue.valid) {

      if (!confirm(`Are you sure you want to save these changes?`)) {
        this.spinner.hide();
        return;
      }

      // JSON Data to be sent to the server
      const data = {
        'roleName': this.formValue.value.roleName,
        'description': this.formValue.value.description,
        'roleGuid': this.formValue.value.roleGuid,
      };

      // auto click close button
      const cancelRef = document.getElementById('cancel');

      this.roleService.updateRole(data).subscribe(results => {
        // console.log(results);
        this.spinner.hide();
        this.getRoles(); // reload data on the screen
        cancelRef?.click();
        console.log(results);
        this.notifier.notify('success', results?.responseMessage);
      }, error => {
        this.spinner.hide();
        console.log(error);
        if (error.status === 401) {
          this.notifier.notify('error', 'You need to login again');
          this.router.navigateByUrl('login');
        } else if (error.status === 409) {
          this.notifier.notify('warning', 'Already exists');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator'
          );
        }
        cancelRef?.click();
        // this.spinner.hide();
      });

      this.initializeForm(); // clear form
    } else {
      this.spinner.hide();
      console.log('Form not valid!!!!');
    }
  }

  // save changes, this method is called on save cchaanges button
  saveChanges() {
    if (this.newRoleEvent) {
      this.addNewRole(); // add new role
    } else {
      this.updateRole(); // update existing role
    }
  }

  // Browse/Open role information
  openRole(role: any) {
    console.log(role);
    this.newRoleEvent = false;
    this.formValue.reset();
    const btnRole = document.getElementById('btnRole');
    btnRole.click();

    this.formValue.controls['roleName'].setValue(role.roleName);
    this.formValue.controls['description'].setValue(role.description);
    this.formValue.controls['roleGuid'].setValue(role.roleGuid);
  }

  // Delete Role
  deleteRole(role: any) {
    this.spinner.show(); // showing loader spinner

    if (!confirm(`Are you sure you want to delete this role (${role.roleName})?`)) {
      this.spinner.hide();
      return;
    }

    // auto click close button
    const cancelRef = document.getElementById('cancel');

    this.roleService.deleteRole(role.roleGuid).subscribe(results => {
      // console.log(results);
      this.spinner.hide();
      this.getRoles(); // reload data on the screen
      cancelRef?.click();
      console.log(results);
      this.notifier.notify('success', results?.responseMessage);
    }, error => {
      this.spinner.hide();
      console.log(error);
      if (error.status === 401) {
        this.notifier.notify('error', 'You need to login again');
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator'
        );
      }
      cancelRef?.click();
      // this.spinner.hide();
    });
  }

}
