import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { CogtaAssetsService } from '../../../services/assets/cogta-assets.service';
import { ConstantsService } from '../../../services/constants/constants.service';




@Component({
  selector: 'app-cogta',
  templateUrl: './cogta.component.html',
  styleUrls: ['./cogta.component.scss']
})
export class CogtaComponent implements OnInit {
  formValue: FormGroup;
  newCogtaAssetEvent: boolean;
  Assets: any;
  notifier: NotifierService;
  results: any

  constructor(
    private cogtaAssetsService: CogtaAssetsService,
    private router: Router,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private constantsService: ConstantsService,
    private notifierService: NotifierService,

  ) {
    this.notifier = notifierService;
   }

  ngOnInit(): void {
    this.initForm();
    this.getAssets();
    
  }

  initForm() {
    this.newCogtaAssetEvent = true;
    this.formValue = this.formBuilder.group({
    assetId: new FormControl('', [Validators.required]),
    barcode: new FormControl('', [Validators.required]),
    serialNumber: new FormControl('', [Validators.required]),
    siteDescription: new FormControl('', [Validators.required]),
    subSite: new FormControl('', [Validators.required]),
    assetDescription: new FormControl('', [Validators.required]),
    gpsLatitude: new FormControl('', [Validators.required]),
    gpsLongitude: new FormControl('', [Validators.required]),
    quantity: new FormControl('', [Validators.required]),
    custodian: new FormControl('', [Validators.required]),
    province: new FormControl('', [Validators.required]),
    assetType: new FormControl('', [Validators.required]),
    department: new FormControl('', [Validators.required]),
    significance: new FormControl('', [Validators.required]),
    costCentre: new FormControl('', [Validators.required]),
    costPrice: new FormControl('', [Validators.required]),
    deliveryDate: new FormControl('', [Validators.required]),
    comment: new FormControl('', [Validators.required]),
    notes: new FormControl('', [Validators.required]),
    purchaseDate: new FormControl('', [Validators.required]),
    supplierName: new FormControl('', [Validators.required]),
    assetCondition: new FormControl('', [Validators.required]),
    invoiceNumber: new FormControl('', [Validators.required]),
    createdUserGuid: new FormControl('', [Validators.required]),
    updatedUserGuid: new FormControl('', [Validators.required]),
    dateUpdated: new FormControl('', [Validators.required]),
    dateAdded: new FormControl('', [Validators.required]),
    syncGuid: new FormControl('', [Validators.required]),

     
    });
  }

  getAssets() {
    this.spinner.show(); // showing loader spinner
    this.cogtaAssetsService.getAllAssets().subscribe((results) => {
      console.log('Assets', results);
      this.Assets = results;
      this.spinner.hide(); // closing loading spinner
    }, error => {
      this.spinner.hide(); // closing loading spinner
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator');
      }
    });
  }

  openAsset(asset: any) {
    console.log(asset);
    this.newCogtaAssetEvent
    this.formValue.reset();
    const btnAsset = document.getElementById('btnAsset');
    btnAsset.click();


    this.formValue.controls['assetId'].setValue(asset.assetId);
    this.formValue.controls['barcode'].setValue(asset.barcode);
    this.formValue.controls['siteDescription'].setValue(asset.siteDescription);
    this.formValue.controls['subSite'].setValue(asset.subSite);
    this.formValue.controls['quantity'].setValue(asset.quantity);
    this.formValue.controls['assetDescription'].setValue(asset.assetDescription);
    this.formValue.controls['gpsLatitude'].setValue(asset.gpsLatitude);
    this.formValue.controls['gpsLongitude'].setValue(asset.gpsLongitude);
    this.formValue.controls['custodian'].setValue(asset.custodian);
    this.formValue.controls['province'].setValue(asset.province);
    this.formValue.controls['assetType'].setValue(asset.assetType);
    this.formValue.controls['serialNumber'].setValue(asset.serialNumber);
    this.formValue.controls['department'].setValue(asset.department);
    this.formValue.controls['significance'].setValue(asset.classification);
    this.formValue.controls['costPrice'].setValue(asset.costPrice);
    this.formValue.controls['deliveryDate'].setValue(asset.deliveryDate);
    this.formValue.controls['costCentre'].setValue(asset.costCentre);
    this.formValue.controls['notes'].setValue(asset.notes);
    this.formValue.controls['assetCondition'].setValue(asset.assetCondition);
    this.formValue.controls['purchaseDate'].setValue(asset.purchaseDate);
    this.formValue.controls['supplierName'].setValue(asset.supplierName);
    this.formValue.controls['invoiceNumber'].setValue(asset.supplierName);
    this.formValue.controls['updatedUserGuid'].setValue(asset.updatedUserGuid);
    this.formValue.controls['createdUserGuid'].setValue(asset.createdUserGuid);
    this.formValue.controls['dateUpdated'].setValue(asset.dateUpdated);
    this.formValue.controls['dateAdded'].setValue(asset.dateAdded);
    this.formValue.controls['syncGuid'].setValue(asset.syncGuid);
    
    }
}
