import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from '../../../environments/environment';
import { ConstantsService } from '../constants/constants.service';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ComponentTypeService {

  constructor(private http: HttpClient, private constants: ConstantsService) { }

searchComponent(field: any, value: any): Observable<any> {
  return this.http.get(`${baseUrl}/api/assetopti/config/component-type/v1/search-component-type-by-any-field/${field}/${value}`, { headers: this.constants.getHeaders() });
      }

// Get all 
getAllComponents(): Observable<any> {
  return this.http.get(`${baseUrl}/api/assetopti/config/component-type/v1/list-all-component-type`, { headers: this.constants.getHeaders() });
}

// Add 
addComponent(data: any): Observable<any> {
  return this.http.post(`${baseUrl}/api/assetopti/config/component-type/v1/create-componet-type`, data, { headers: this.constants.getHeaders() });
}

// Update 
updateComponent(data: any): Observable<any> {
  return this.http.put(`${baseUrl}/api/assetopti/config/component-type/v1/update-component-type`, data, { headers: this.constants.getHeaders() });
}

// Delete 
deleteComponent(componentTypeGuid: any): Observable<any> {
  return this.http.delete(`${baseUrl}/api/assetopti/config/component-type/v1/delete-component-type/${componentTypeGuid}`, { headers: this.constants.getHeaders() });
}

}


