import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClientsService } from '../../../services/account/clients.service';
import { ConstantsService } from '../../../services/constants/constants.service';

@Component({
  selector: 'app-mani-clients',
  templateUrl: './mani-clients.component.html',
  styleUrls: ['./mani-clients.component.scss']
})
export class ManiClientsComponent implements OnInit {

  /**testList: any [] = ['Test1', 'Test2', 'Test3', 'Test4', 'Test5'];*/

  clients: any[] = [];
  formValue !: FormGroup;
  private readonly notifier: NotifierService;

  newClientEvent = true;

  constructor(
    private clientService: ClientsService,
    private router: Router,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private constantsService: ConstantsService,
    private notifierService: NotifierService
  ) {
     this.notifier = notifierService; 
    }

  ngOnInit(): void {
    this.initializeForm();
    this.getClients();
  }

  initializeForm() {
    this.newClientEvent = true;
    this.formValue = this.formBuilder.group({
      clientName: new FormControl('', [Validators.required]),
     // clientId: new FormControl('', [Validators.required]),
      maniClientGuid: new FormControl(''),
    });
  }

  getClients() {
    this.spinner.show(); // showing loader spinner
    this.clientService.getAllClients().subscribe((results) => {
      console.log('Clients', results);
      this.clients = results;
      this.spinner.hide(); // closing loading spinner
    }, error => {
      this.spinner.hide(); // closing loading spinner
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
        // this.constantsService.notify(error.error.responseMessage, this.constantsService.DANGER);
      } else {
        // this.constantsService.notify('System error occurred, please contact Administrator!', this.constantsService.DANGER);
      }
    });
  }

  addNewClient() {
    this.spinner.show(); // showing loader spinner
    console.log(this.formValue.value);

    if (this.formValue.valid) {

      // JSON Data to be sent to the server
      const data = {
        'clientName': this.formValue.value.clientName,
        'maniClientGuid': this.formValue.value.maniClientGuid,
 
      };

      // auto click close button
      const cancelRef = document.getElementById('cancel');

      this.clientService.addClient(data).subscribe(results => {
        // console.log(results);
        this.spinner.hide();
        this.getClients(); // reload data on the screen
        cancelRef?.click();
        console.log(results);
        this.notifier.notify('success', results?.responseMessage);
      }, error => {
        this.spinner.hide();
        console.log(error);
        if (error.status === 401) {
          this.notifier.notify('error', 'You need to login again');
          this.router.navigateByUrl('login');
        } else if (error.status === 409) {
          this.notifier.notify('warning', 'Already exists');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator'
          );
        }
        cancelRef?.click();
        // this.spinner.hide();
      });

      this.initializeForm(); // clear form
    } else {
      this.spinner.hide();
      this.notifier.notify('error', 'Fill in all the form fields');
    }
  }

  // Update 
  updateClient() {
    this.spinner.show(); // showing loader spinner
    console.log(this.formValue.value);

    if (this.formValue.valid) {

      if (!confirm(`Are you sure you want to save these changes?`)) {
        this.spinner.hide();
        return;
      }

      // JSON Data to be sent to the server
      const data = {
        'clientName': this.formValue.value.clientName,
        'maniClientGuid': this.formValue.value.maniClientGuid,
      };

      // auto click close button
      const cancelRef = document.getElementById('cancel');

      this.clientService.updateClient(data).subscribe(results => {
        // console.log(results);
        this.spinner.hide();
        this.getClients(); // reload data on the screen
        cancelRef?.click();
        console.log(results);
        this.notifier.notify('success', results?.responseMessage);
      }, error => {
        this.spinner.hide();
        console.log(error);
        if (error.status === 401) {
          this.notifier.notify('error', 'You need to login again');
          this.router.navigateByUrl('login');
        } else if (error.status === 409) {
          this.notifier.notify('warning', 'Already exists');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator'
          );
        }
        cancelRef?.click();
        // this.spinner.hide();
      });

      this.initializeForm(); // clear form
    } else {
      this.spinner.hide();
      console.log('Form not valid!!!!');
    }
  }

  // save changes, this method is called on save cchaanges button
  saveChanges() {
    if (this.newClientEvent) {
      this.addNewClient(); // add new role
    } else {
      this.updateClient(); // update existing role
    }
  }

  // Browse/Open client information
  openClient(client: any) {
    console.log(client);
    this.newClientEvent = false;
    this.formValue.reset();
    const btnClient = document.getElementById('btnClient');
    btnClient.click();

    this.formValue.controls['clientName'].setValue(client.clientName);
    this.formValue.controls['maniClientGuid'].setValue(client.maniClientGuid);
  
  }

  // Delete 
  deleteClient(client: any) {
    this.spinner.show(); // showing loader spinner

    if (!confirm(`Are you sure you want to delete this client (${client.clientName})?`)) {
      this.spinner.hide();
      return;
    }

    // auto click close button
    const cancelRef = document.getElementById('cancel');

    this.clientService.deleteClient(client.maniClientGuid).subscribe(results => {
      // console.log(results);
      this.spinner.hide();
      this.getClients(); // reload data on the screen
      cancelRef?.click();
      console.log(results);
      this.notifier.notify('success', results?.responseMessage);
    }, error => {
      this.spinner.hide();
      console.log(error);
      if (error.status === 401) {
        this.notifier.notify('error', 'You need to login again');
        this.router.navigateByUrl('login');
      } else {
        this.notifier.notify('error', 'Error occcurred, please contact Administrator'
        );
      }
      cancelRef?.click();
      // this.spinner.hide();
    });
  }


}
