import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from '../../../environments/environment';
import { ConstantsService } from '../constants/constants.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AssetDescriptionService {

  constructor(private http: HttpClient, private constants: ConstantsService) { }

searchAsset(field: any, value: any): Observable<any> {
  return this.http.get(`${baseUrl}/api/assetopti/config/asset-description/v1/search-asset-description-by-anything/${field}/${value}`, { headers: this.constants.getHeaders() });
    }

  // Get all 
getAllAssets(): Observable<any> {
  return this.http.get(`${baseUrl}/api/assetopti/config/asset-description/v1/list-all-asset-description`, { headers: this.constants.getHeaders() });
}

// Add 
addAsset(data: any): Observable<any> {
  return this.http.post(`${baseUrl}/api/assetopti/config/asset-description/v1/add-asset-description`, data, { headers: this.constants.getHeaders() });
}

// Update 
updateAsset(data: any): Observable<any> {
  return this.http.put(`${baseUrl}/api/assetopti/config/asset-description/v1/update-asset-description`, data, { headers: this.constants.getHeaders() });
}

// Delete 
deleteAsset(assetDescGuid: any): Observable<any> {
  return this.http.delete(`${baseUrl}/api/assetopti/config/asset-description/v1/delete-asset-description/${assetDescGuid}`, { headers: this.constants.getHeaders() });
}
}
