import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from '../../../environments/environment';
import { ConstantsService } from '../constants/constants.service';
import { TokenService } from '../constants/token.service';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(private http: HttpClient, private tokenStorageService: TokenService, private constants: ConstantsService) { }

  // Get All Roles
  getAllRoles(): Observable<any> {
    return this.http.get(`${baseUrl}/api/assetopti/role/v1/list-all-roles`, { headers: this.constants.getHeaders() });
  }

  // Add Role
  addRole(data: any): Observable<any> {
    return this.http.post(`${baseUrl}/api/assetopti/role/v1/add-role`, data, { headers: this.constants.getHeaders() });
  }

  // Update Role
  updateRole(data: any): Observable<any> {
    return this.http.put(`${baseUrl}/api/assetopti/role/v1/update-role`, data, { headers: this.constants.getHeaders() });
  }

  // Delete Role
  deleteRole(roleGuid: any): Observable<any> {
    return this.http.delete(`${baseUrl}/api/assetopti/role/v1/delete-role/${roleGuid}`, { headers: this.constants.getHeaders() });
  }

}
