import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { baseUrl } from '../../../environments/environment';
import { ConstantsService } from '../constants/constants.service';
import { TokenService } from '../constants/token.service';

@Injectable({
  providedIn: 'root'
})
export class DbsaAssetService {

  constructor(private http: HttpClient, private tokenStorageService: TokenService, private constants: ConstantsService) { }

  getAllDbsaAsset(): Observable<any> {
    return this.http.get(`${baseUrl}/api/assetopti/dbsa/dbsa-asset/v1/list-all-dbsa-asset`, { headers: this.constants.getHeaders() });
  }
  
  //Add 
  addDbsaAsset(data: any): Observable<any> {
   return this.http.post(`${baseUrl}/api/assetopti/dbsa/dbsa-asset/v1/create-dbsa-asset`, data, { headers: this.constants.getHeaders() });
  }

  // Update 
  updateDbsaAsset(data: any): Observable<any> {
   return this.http.put(`${baseUrl}/api/assetopti/dbsa/dbsa-asset/v1/update-dbsa-asset`, data, { headers: this.constants.getHeaders() });
  }

   //Delete 
  deleteDbsaAsset(dbsaAssetGuid: any): Observable<any> {
  return this.http.delete(`${baseUrl}/api/assetopti/dbsa/dbsa-asset/v1/delete-dbsa-asset/${dbsaAssetGuid}`, { headers: this.constants.getHeaders() });
  }

  searchDbsaAsset(dbsaAssetGuid: any): Observable<any> {
    return this.http.delete(`${baseUrl}/api/assetopti/dbsa/dbsa-asset/v1/search-dbsa-asset-by-anything${dbsaAssetGuid}`, { headers: this.constants.getHeaders() });
    }


}
