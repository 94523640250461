import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from '../../../environments/environment';
import { ConstantsService } from '../constants/constants.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AccSubGroupService {

  constructor(private http: HttpClient, private constants: ConstantsService) { }


  // Search 
  searchAccSubGroup(field: any, value: any): Observable<any> {
    return this.http.get(`${baseUrl}/api/assetopti/config/accounting-sub-group/v1/search-accounting-sub-group-by-any-field/${field}/${value}`, { headers: this.constants.getHeaders() });
  }

  // Get all 
  getAllSubGroup(): Observable<any> {
    return this.http.get(`${baseUrl}/api/assetopti/config/accounting-sub-group/v1/list-all-accounting-sub-group`, { headers: this.constants.getHeaders() });
  }

  // Add 
  addSubGroup(data: any): Observable<any> {
    return this.http.post(`${baseUrl}/api/assetopti/config/accounting-sub-group/v1/create-accounting-sub-group`, data, { headers: this.constants.getHeaders() });
  }

  // Update 
  updateSubGroup(data: any,): Observable<any> {
    return this.http.put(`${baseUrl}/api/assetopti/config/accounting-sub-group/v1/update-accounting-sup-group`, data, { headers: this.constants.getHeaders() });
  }

  // Delete 
  deleteSubGroup(accSubGroupGuid: any): Observable<any> {
    return this.http.delete(`${baseUrl}/api/assetopti/config/accounting-sub-group/v1/delete-accounting-sub-group/${accSubGroupGuid}`, { headers: this.constants.getHeaders() });
  }
}
