import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { ComponentTypeService } from '../../../../services/configs/component-type.service';
import { ConstantsService } from '../../../../services/constants/constants.service';
import { ClientsService } from '../../../../services/account/clients.service';
import { AssetDescriptionService} from '../../../../services/configs/asset-description.service';
import { AccSubGroupService} from '../../../../services/configs/acc-sub-group.service';
import { AssetClassService} from '../../../../services/configs/asset-class.service';
import { AssetGroupTypeService} from '../../../../services/configs/asset-group-type.service';
import { AccountingGroupService} from '../../../../services/configs/accounting-group.service';



@Component({
  selector: 'app-component-type',
  templateUrl: './component-type.component.html',
  styleUrls: ['./component-type.component.scss']
})
export class ComponentTypeComponent implements OnInit {
  newComponentEvent: boolean;
  formValue: FormGroup;
  notifier: NotifierService;
  clients: any[] = [];
  components: any[] = [];
  assets: any[]= [];
  SubGroups: any;
  groups: any;
  classAssets: any;
  groupTypes: any;


  constructor(
    private  componentTypeService:  ComponentTypeService,
    private router: Router,
    private formBuilder: FormBuilder,
    private clientsService: ClientsService,
    private accountingGroupService: AccountingGroupService,
    private accSubGroupService: AccSubGroupService,
    private assetGroupTypeService: AssetGroupTypeService,
    private assetClassService: AssetClassService,
    private assetDescriptionService: AssetDescriptionService,
    private spinner: NgxSpinnerService,
    private constantsService: ConstantsService,
    private notifierService: NotifierService
  ) { 
    this.notifier = notifierService;
  }

  ngOnInit(): void {
    this.initForm();
    this.getComponents();
    this.getClients();
    
  }

  initForm() {
    this.newComponentEvent = true;
    this.formValue = this.formBuilder.group({
      componentTypeName: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      company: new FormControl('', [Validators.required]),
      assetDescription: new FormControl('', [Validators.required]),
      accountingGroup: new FormControl('', [Validators.required]),
      accountingSubGroup: new FormControl('', [Validators.required]),
      assetClass: new FormControl('', [Validators.required]),
      assetGroupType: new FormControl('', [Validators.required]),
      componentTypeGuid: new FormControl(''),
    
   
      });
    }
    getComponents() {
      this.spinner.show(); // showing loader spinner
      this.componentTypeService.getAllComponents().subscribe((results) => {
        console.log('Components', results);
        this.components = results;
        this.spinner.hide(); // closing loading spinner
      }, error => {
        this.spinner.hide(); // closing loading spinner
        console.warn(error);
        if (error.status === 401) {
          this.router.navigateByUrl('login');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator');
        }
      });
    }
    getClients() {
      this.spinner.show(); // showing loader spinner
      this.clientsService.getAllClients().subscribe((results) => {
        console.log('Clients', results);
        this.clients = results;
        this.spinner.hide(); // closing loading spinner
      }, error => {
        this.spinner.hide(); // closing loading spinner
        console.warn(error);
        if (error.status === 401) {
          this.router.navigateByUrl('login');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator');
        }
      });
    }

    getAssets(value: any) {
      this.spinner.show(); // showing loader spinner
      this.assetDescriptionService.searchAsset('maniClientGuid', value).subscribe((results) => {
        console.log('Assets', results);
        this.assets = results;
        this.spinner.hide(); // closing loading spinner
      }, error => {
        this.spinner.hide(); // closing loading spinner
        console.warn(error);
        if (error.status === 401) {
          this.router.navigateByUrl('login');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator');
        }
      });
    }

    getGroupType(value: any) {
      this.spinner.show(); // showing loader spinner
      this.assetGroupTypeService.searchGroupType('maniClientGuid', value).subscribe((results) => {
        console.log('GroupTypes', results);
        this.groupTypes = results;
        this.spinner.hide(); // closing loading spinner
      }, error => {
        this.spinner.hide(); // closing loading spinner
        console.warn(error);
        if (error.status === 401) {
          this.router.navigateByUrl('login');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator');
        }
      });
    }
  
    getClassAssets(value: any) {
      this.spinner.show(); // showing loader spinner
      this.assetClassService.searchClassAsset('maniClientGuid', value).subscribe((results) => {
        console.log('ClassAssets', results);
        this.classAssets = results;
        this.spinner.hide(); // closing loading spinner
      }, error => {
        this.spinner.hide(); // closing loading spinner
        console.warn(error);
        if (error.status === 401) {
          this.router.navigateByUrl('login');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator');
        }
      });
    }
  
    getGroup(value: any) {
      this.spinner.show(); // showing loader spinner
      this.accountingGroupService.searchAccountingGroup('maniClientGuid', value).subscribe((results) => {
        console.log('Groups', results);
        this.groups = results;
        this.spinner.hide(); // closing loading spinner
      }, error => {
        this.spinner.hide(); // closing loading spinner
        console.warn(error);
        if (error.status === 401) {
          this.router.navigateByUrl('login');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator');
        }
      });
    }
  
  
    getSubGroup(value: any) {
      this.spinner.show(); // showing loader spinner
      this.accSubGroupService.searchAccSubGroup('maniClientGuid', value).subscribe((results) => {
        console.log('SubGroups', results);
        this.SubGroups = results;
        this.spinner.hide(); // closing loading spinner
      }, error => {
        this.spinner.hide(); // closing loading spinner
        console.warn(error);
        if (error.status === 401) {
          this.router.navigateByUrl('login');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator');
        }
      });
    }

    onCompanyChange(value: any){
      console.log(value);
      this.getGroupType(value);
      this.getClassAssets(value);
      this.getGroup(value);
      this.getSubGroup(value);
      this.getAssets(value);
    }
  
  
    addNewComponent() {
      this.spinner.show(); // showing loader spinner
      console.log(this.formValue.value);
  
      if (this.formValue.valid) {
  
        // JSON Data to be sent to the server
        const data = {
          'componentTypeName': this.formValue.value.componentTypeName,
          'description': this.formValue.value.description,
          'maniClientGuid': this.formValue.value.company,
          'assetDescGuid': this.formValue.value.assetDescription,
          'componentTypeGuid': this.formValue.value.componentType,
          'assetGroupTypeGuid': this.formValue.value.assetGroupType,
          'accSubGroupGuid': this.formValue.value.accountingSubGroup,
          'accountingGroupGuid': this.formValue.value.accountingGroup,
          'assetClassGuid': this.formValue.value.assetClass  
          
        };
  
        // auto click close button
        const cancelRef = document.getElementById('cancel');
  
        this.componentTypeService.addComponent(data).subscribe(results => {
          console.log(results);
          this.spinner.hide();
          this.getComponents(); // reload data on the screen
          cancelRef?.click();
          console.log(results);
          this.notifier.notify('success', results?.responseMessage);
        }, error => {
          this.spinner.hide();
          console.log(error);
          if (error.status === 401) {
            this.notifier.notify('error', 'You need to login again');
            this.router.navigateByUrl('login');
          } else if (error.status === 409) {
            this.notifier.notify('warning', 'Already exists');
          } else {
            this.notifier.notify('error', 'Error occcurred, please contact Administrator'
            );
          }
          cancelRef?.click();
          // this.spinner.hide();
        });
  
        this.initForm(); // clear form
      } else {
        this.spinner.hide();
        this.notifier.notify('error', 'Fill in all the form fields');
      }
    }
  
    updateComponent() {
      this.spinner.show(); // showing loader spinner
      console.log(this.formValue.value);
  
      if (this.formValue.valid) {
  
        if (!confirm(`Are you sure you want to save these changes?`)) {
          this.spinner.hide();
          return;
        }
  
        // JSON Data to be sent to the server
        const data = {
          
          'componentTypeName': this.formValue.value.componetTypeName,
          'description': this.formValue.value.description,
          'maniClientGuid': this.formValue.value.company,
          'assetDescGuid': this.formValue.value.assetDescription,
          'componentTypeGuid': this.formValue.value.componentType,
          'assetGroupTypeGuid': this.formValue.value.assetGroupType,
          'accSubGroupGuid': this.formValue.value.accountingSubGroup,
          'accountingGroupGuid': this.formValue.value.accountingGroup,
          'assetClassGuid': this.formValue.value.assetClass  
          
          
        };
  
        // auto click close button
        const cancelRef = document.getElementById('cancel');
  
        this.componentTypeService.updateComponent(data).subscribe(results => {
          // console.log(results);
          this.spinner.hide();
          this.getComponents(); // reload data on the screen
          cancelRef?.click();
          console.log(results);
          this.notifier.notify('success', results?.responseMessage);
        }, error => {
          this.spinner.hide();
          console.log(error);
          if (error.status === 401) {
            this.notifier.notify('error', 'You need to login again');
            this.router.navigateByUrl('login');
          } else if (error.status === 409) {
            this.notifier.notify('warning', 'Already exists');
          } else {
            this.notifier.notify('error', 'Error occcurred, please contact Administrator'
            );
          }
          cancelRef?.click();
          // this.spinner.hide();
        });
  
        this.initForm(); // clear form
      } else {
        this.spinner.hide();
        console.log('Form not valid!!!!');
      }
    }
    
    saveChanges() {
      if (this.newComponentEvent) {
        this.addNewComponent(); // add new role
      } else {
        this.updateComponent(); // update existing role
      }
    }
  
  
    
    openComponent(component: any) {
      console.log(component);
      this.newComponentEvent = false;
      this.formValue.reset();
      const btnComponentType = document.getElementById('btnComponentType');
      btnComponentType.click();
     
  
     
      this.formValue.controls['componentTypeName'].setValue(component.componentTypeName);
      this.formValue.controls['description'].setValue(component.description);
      this.formValue.controls['company'].setValue(component.maniClientGuid);
      this.formValue.controls['assetDescription'].setValue(component.assetDescGuid);
      this.formValue.controls['componentTypeGuid'].setValue(component.componentTypeGuid);
      this.formValue.controls['assetGroupType'].setValue(component.assetGroupTypeGuid);
      this.formValue.controls['accountingGroup'].setValue(component.accountingGroupGuid);
      this.formValue.controls['accountingSubGroup'].setValue(component.accSubGroupGuid);
      this.formValue.controls['assetClass'].setValue(component.assetClassGuid);
    }
  
  
    deleteComponent(component: any) {
      this.spinner.show(); // showing loader spinner
  
      if (!confirm(`Are you sure you want to delete this group (${component.componentTypeName})?`)) {
        this.spinner.hide();
        return;
      }
  
      // auto click close button
      const cancelRef = document.getElementById('cancel');
  
      this.componentTypeService.deleteComponent(component.componentTypeGuid).subscribe(results => {
        // console.log(results);
        this.spinner.hide();
        this.getComponents(); // reload data on the screen
        cancelRef?.click();
        console.log(results);
        this.notifier.notify('success', results?.responseMessage);
      }, error => {
        this.spinner.hide();
        console.log(error);
        if (error.status === 401) {
          this.notifier.notify('error', 'You need to login again');
          this.router.navigateByUrl('login');
        } else {
          this.notifier.notify('error', 'Error occcurred, please contact Administrator'
          );
        }
        cancelRef?.click();
        // this.spinner.hide();
      });
    }
  
}
