import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { TokenService } from '../constants/token.service';
import { ConstantsService } from '../constants/constants.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient, private tokenStorageService: TokenService, private constants: ConstantsService) { }

  // Login
  login(credentials: { username: any; password: any; }): Observable<any> {
    return this.http.post(`${baseUrl}/api/assetopti/user/v1/login`, {
      email: credentials.username,
      password: credentials.password
    }, { headers: this.constants.emptyHeaders });
  }
}
