import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/account/user.service';
import { ConstantsService } from '../../services/constants/constants.service';
import { TokenService } from '../../services/constants/token.service';
import { navItems } from '../../_nav';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {

  public sidebarMinimized = false;
  public navItems = [];
  rawNavItems = navItems;
  fullName = '';
  userRole = '';
  businessName = '';
  userRoles = [];

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  constructor(
    private userService: UserService,
    private router: Router,
    private constants: ConstantsService,
    private tokenService: TokenService
    // private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.getUserProfile();
    this.filterRoles();
  }

  // IDC Users
  filterRoles() {
    console.log(this.userRoles);
    if (this.userRoles.includes('MANI Administrator')) {
      this.navItems = this.rawNavItems;
    } else {
      for (let i = 0; i < this.rawNavItems.length; i++) {

        if (this.rawNavItems[i].children === null && this.userRoles.includes(this.rawNavItems[i].name)) {
          this.navItems.push(this.rawNavItems[i]);
          console.log(this.navItems[i]);
          console.log('***************************');
        }

        if (this.rawNavItems[i].children != null && this.rawNavItems[i].children.length > 0) {
          for (let j = 0; j < this.rawNavItems[i].children.length; j++) {
            if (this.userRoles.includes(this.rawNavItems[i].children[j].name)) {

              const temp = this.rawNavItems[i];
              const children = temp.children;
              const childrenList = [];

              for (let xx = 0; xx < children.length; xx++) {
                if (this.userRoles.includes(children[xx].name)) {
                  childrenList.push(children[xx]);
                }
              }

              temp.children = childrenList;

              this.navItems.push(temp);
            }
          }
        }
      }
    }
  }

  async getUserProfile() {

    const token = this.tokenService.getToken();

    if (token === null) {
      this.router.navigateByUrl('login');
    } else {
      console.log(this.constants.getJwtClaims());
      this.fullName = this.constants.getJwtClaims()['fullnames'];
      this.businessName = this.constants.getJwtClaims()['companyName'];
      this.userRoles = this.constants.getJwtClaims()['groups'];
    }

    /**this.userService.getUserProfile().subscribe((results) => {
      console.log('DefaultLayoutComponent', results);
      this.fullName = results[0].userFullDetails.personalInformation.firstName;
      this.fullName += ' ' + results[0].userFullDetails.personalInformation.lastName;

      this.businessName = results[0].businessInformation.business.businessName;
      }, error => {
        console.warn(error);
        if (error.status === 401) {
          this.router.navigateByUrl('login');
          // this.constantsService.notify(error.error.responseMessage, this.constantsService.DANGER);
        } else {
          // this.constantsService.notify('System error occurred, please contact Administrator!', this.constantsService.DANGER);
        }
      });*/
  }
}
