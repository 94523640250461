import { Injectable } from '@angular/core';



@Injectable({
  providedIn: 'root'
})
export class TokenService {

  public TOKEN_KEY = 'auth-token-assetopti-2022';
  public USER_KEY = 'auth-user-assetopti-2022';

  constructor() { }

  signOut(): void {
    window.sessionStorage.clear();
    localStorage.clear();
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(this.TOKEN_KEY);
    window.sessionStorage.setItem(this.TOKEN_KEY, token);

    localStorage.removeItem(this.TOKEN_KEY);
    localStorage.setItem(this.TOKEN_KEY, token);
  }

  public getToken(): string {
    // return sessionStorage.getItem(TOKEN_KEY)!;
    return localStorage.getItem(this.TOKEN_KEY);
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(this.USER_KEY);
    window.sessionStorage.setItem(this.USER_KEY, JSON.stringify(user));

    localStorage.removeItem(this.USER_KEY);
    localStorage.setItem(this.USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    return JSON.parse(sessionStorage.getItem(this.USER_KEY));
  }
}
