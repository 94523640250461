import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver'

@Injectable({
  providedIn: 'root'
})
export class DbsaExcelService {

  constructor() { }

  public generateExcelFile(data: any[]) {
    const title = 'List of assets';
    const headers = [
'assetCondition',    
'assetDescription',
'assetLocation',
'barcode',
'assetLocation',
'conditionComment',
'componentReference',
'custodian',
'dateAdded',
'dateUpdated',
'deleted',
'department',
'townName',
'gpsLatitude',
'gpsLongitude',
'streetName',
'dbsaAssetId',
'operationalStatus',
'overalComments',
'serialNumber',
'sizeCapacity',
'syncGuid',
'userGuidCreated',
'userGuidLastUpdated',
'dbsaAssetGuid',
      'pictures'
    ];

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('report');
    const headerRow = worksheet.addRow(headers);

    for (let i in data) {
      let list = [];
      list.push(data[i].primaryAsset.assetCondition);
      list.push(data[i].primaryAsset.assetDescription);
      list.push(data[i].primaryAsset.assetLocation);
      list.push(data[i].primaryAsset.barcode);
      list.push(data[i].primaryAsset.assetLocation);
      list.push(data[i].primaryAsset.conditionComment);
      list.push(data[i].primaryAsset.componentReference);
      list.push(data[i].primaryAsset.custodian);
      list.push(data[i].primaryAsset.dateAdded);
      list.push(data[i].primaryAsset.dateUpdated);
      list.push(data[i].primaryAsset.deleted);
      list.push(data[i].primaryAsset.department);
      list.push(data[i].primaryAsset.townName);
      list.push(data[i].primaryAsset.gpsLatitude);
      list.push(data[i].primaryAsset.gpsLongitude);
      list.push(data[i].primaryAsset.streetName);
      list.push(data[i].primaryAsset.dbsaAssetId);
      list.push(data[i].primaryAsset.operationalStatus);
      list.push(data[i].primaryAsset.overalComments);
      list.push(data[i].primaryAsset.serialNumber);
      list.push(data[i].primaryAsset.sizeCapacity);
      list.push(data[i].primaryAsset.syncGuid);
      list.push(data[i].primaryAsset.userGuidCreated);
      list.push(data[i].primaryAsset.userGuidLastUpdated);
      list.push(data[i].primaryAsset.dbsaAssetGuid);

      for (let k in data[i].images) {
        list.push("https://zamambo.dedicated.co.za/mani-am/secret_asset_picture_kwadukuza.php?pic=" + data[i].images[k].fileName);
      }

      worksheet.addRow(list);
    }
   
    
    // worksheet.addRows(data);

    workbook.xlsx.writeBuffer().then((res) => {
      const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'DBSAReport.xlsx');
    });
  }
}


