import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { ClientsComponent } from './views/clients/clients.component';
import { EmployeesComponent } from './views/employees/employees.component';
//import { UsersComponent } from './views/administration/users/users.component';


export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'users',
        loadChildren: () => import('./views/administration/mani-users/mani-users-routing.module').then(m => m.ManiUsersRoutingModule)
      },
      {
        path: 'roles',
        loadChildren: () => import('./views/administration/roles/roles-routing.module').then(m => m.RolesRoutingModule)
      },
      {
        path: 'asset-description',
        loadChildren: () => import('./views/administration/configs/asset-description/asset-description-routing.module').then(m => m.AssetDescriptionRoutingModule)
      },
      {
        path: 'component-type',
        loadChildren: () => import('./views/administration/configs/component-type/component-type-routing.module').then(m => m.ComponentTypeRoutingModule)
      },
      {
        path: 'mani-clients',
        loadChildren: () => import('./views/administration/mani-clients/mani-clients-routing.module').then(m => m.ManiClientsRoutingModule)
      },
      {
        path: 'cogta',
        loadChildren: () => import('./views/fixed-asset-projects/cogta/cogta-routing.module').then(m => m.CogtaRoutingModule)
      },
      {
        path: 'dbsa-dashboard',
        loadChildren: () => import('./views/fixed-asset-projects/dbsa-project/dbsa-dashboard/dbsa-dashboard-routing.module').then(m => m.DbsaDashboardRoutingModule)
      },
      {
        path: 'dbsa',
        loadChildren: () => import('./views/fixed-asset-projects/dbsa/dbsa-routing.module').then(m => m.DbsaRoutingModule)
      },
      {
        path: 'idc',
        loadChildren: () => import('./views/fixed-asset-projects/idc-project/idc/idc-routing.module').then(m => m.IdcRoutingModule)
      },
      {
        path: 'idc-dashboard',
        loadChildren: () => import('./views/fixed-asset-projects/idc-project/idc-dashboard/idc-dashboard-routing.module').then(m => m.IdcDashboardRoutingModule)
      },
      {
        path: 'work-order-list',
        loadChildren: () => import('./views/work-orders/work-order-list/work-order-list-routing.module').then(m => m.WorkOrderListRoutingModule)
      },
      {
        path: 'work-order-dashboard',
        loadChildren: () => import('./views/work-orders/work-order-dashboard/work-order-dashboard-routing.module').then(m => m.WorkOrderDashboardRoutingModule)
      },
      {
        path: 'accounting-group',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./views/administration/configs/accounting-group/accounting-group-routing.module').then(m => m.AccountingGroupRoutingModule)
      },
      {
        path: 'accounting-sub-group',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./views/administration/configs/accounting-sub-group/accounting-sub-group-routing.module').then(m => m.AccountingSubGroupRoutingModule)
      },
      {
        path: 'asset-class',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./views/administration/configs/asset-class/asset-class-routing.module').then(m => m.AssetClassRoutingModule)
      },
      {
        path: 'asset-group-type',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./views/administration/configs/asset-group-type/asset-group-type-routing.module').then(m => m.AssetGroupTypeRoutingModule)
      },
      {
        path: 'employees',
        loadChildren: () => import('./views/employees/employees.module').then(m => m.EmployeesModule)
      },
      {
        path: 'products',
        loadChildren: () => import('./views/products/products.module').then(m => m.ProductsModule)
      },
      /**{
        path: 'branches',
        loadChildren: () => import('./views/branches/').then(m => m.BranchesModule)
      },*/
      {
        path: 'packages',
        loadChildren: () => import('./views/packages/packages.module').then(m => m.PackagesModule)
      },
      {
        path: 'commission',
        loadChildren: () => import('./views/commission/commission.module').then(m => m.CommissionModule)
      },
      {
        path: 'book-keeping',
        loadChildren: () => import('./views/book-keeping/bookKeeping.module').then(m => m.BookKeepingModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./views/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'loyalty-points',
        loadChildren: () => import('./views/loyalty-points/loyaltyPoints.module').then(m => m.LoyaltyPointsModule)
      },
      {
        path: 'gift-cards',
        loadChildren: () => import('./views/gitft-cards/giftCards.module').then(m => m.GiftCardsModule)
      },
      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
