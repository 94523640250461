import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from '../../../environments/environment';
import { ConstantsService } from '../constants/constants.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AssetGroupTypeService {

  constructor(private http: HttpClient, private constants: ConstantsService) { }

searchGroupType(field: any, value: any): Observable<any> {
  return this.http.get(`${baseUrl}/api/assetopti/config/asset-group-type/v1/search-asset-group-type-by-any-field/${field}/${value}`, { headers: this.constants.getHeaders() });
      }

  // Get all 
getAllGroupType(): Observable<any> {
  return this.http.get(`${baseUrl}/api/assetopti/config/asset-group-type/v1/list-all-asset-group-type`, { headers: this.constants.getHeaders() });
}

// Add 
addGroupType(data: any): Observable<any> {
  return this.http.post(`${baseUrl}/api/assetopti/config/asset-group-type/v1/create-asset-group-type`, data, { headers: this.constants.getHeaders() });
}

// Update 
updateGroupType(data: any): Observable<any> {
  return this.http.put(`${baseUrl}/api/assetopti/config/asset-group-type/v1/update-asset-group-type`, data, { headers: this.constants.getHeaders() });
}

// Delete 
deleteGroupType(assetGroupTypeGuid: any): Observable<any> {
  return this.http.delete(`${baseUrl}/api/assetopti/config/asset-group-type/v1/delete-asset-group-type/${assetGroupTypeGuid}`, { headers: this.constants.getHeaders() });
}

}
