import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../services/account/login.service';
import { UserService } from '../../services/account/user.service';
import { ConstantsService } from '../../services/constants/constants.service';
import { TokenService } from '../../services/constants/token.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {

  loginFormGroup = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  private readonly notifier: NotifierService;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private tokenStorage: TokenService,
    private constantsService: ConstantsService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private notifierService: NotifierService
  ) {
    this.notifier = notifierService;
  }

  ngOnInit(): void {
    this.checkUserSession();
  }

  checkUserSession() {
    this.userService.checkUsersession().subscribe((results) => {
      console.log('Login', results);
      if (results.responseCode === 401) {
        // this.constantsService.notify(results.responseMessage, this.constantsService.DANGER);
      } else if (results.responseCode === 200) {
        this.tokenStorage.saveToken(results.results);
        this.router.navigateByUrl('dashboard');
      } else {
        // this.constantsService.notify(results.responseMessage, this.constantsService.DANGER);
      }
    }, error => {
      console.warn(error);
      if (error.status === 401) {
        this.router.navigateByUrl('login');
        // this.constantsService.notify(error.error.responseMessage, this.constantsService.DANGER);
      } else {
        // this.constantsService.notify('System error occurred, please contact Administrator!', this.constantsService.DANGER);
      }
    });
  }

  onLogin() {
    if (this.loginFormGroup.valid) {
      this.spinner.show();
      console.log(this.loginFormGroup.value);
      this.loginService.login(this.loginFormGroup.value).subscribe((results) => {
        this.spinner.hide();
        console.log(results);
        console.log(results.results);
        if (results.responseCode === 401) {
          this.notifier.notify('error', '');
        } else if (results.responseCode === 200) {
          this.tokenStorage.saveToken(results.results);
          this.router.navigateByUrl('dashboard');
        } else {
          this.notifier.notify('warning', results.responseMessage);
        }
      }, error => {
        this.spinner.hide();
        console.warn(error);
        if (error.status === 401) {
          console.log(error.error.responseMessage);
          this.notifier.notify('error', error.error.responseMessage);
          // this.constantsService.notify(error.error.responseMessage, this.constantsService.DANGER);
        } else {
          this.notifier.notify('error', 'Error Occurred');
          // this.constantsService.notify('System error occurred, please contact Administrator!', this.constantsService.DANGER);
        }
      });
    } else {
      this.notifier.notify('error', 'Enter email and your password');
      // this.constantsService.notify('Email/Username & Password is required!!!', this.constantsService.DANGER);
    }
  }
}
